import React, { Component } from 'react';
import { connect } from 'react-redux';
import mobiscroll from "@mobiscroll/react";
import axiosInstance from '../components/axios';
//import CartProducts from '../components/cart/Products';
import CartProductItem from '../components/cart/ProductItem';
import CustomFieldItem from '../components/cart/CustomFieldItem';
import FulfilmentPopup from '../components/products/FulfilmentTabs';
import DeliveryAddress from '../components/checkout/DeliveryAddress';
import DeliverySlot from '../components/checkout/DeliverySlot';
import DeliveryStop from '../components/checkout/DeliveryStop';
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';
import * as actions from '../store/actions/index';
import { validateMobile, validateCardCSC } from '../shared/validate';
import {
    unixDatetimeToDate,
    unixDatetimeToTime,
    unixUtcTimeOffsetMins,
    isProduction,
    updateObject,
    logger,
    isCordova,
    openWindow,
    attachDeviceInfoToData,
    isGoogleMapsLoaded,
    nl2br, isIframe, getPopupResponsiveSettings
} from '../shared/utility';
import { mpoFulfilment } from '../lib/Fulfilment';
import { mpoOneSignal } from '../lib/OneSignal';
import { mpoPayPal } from '../lib/PayPal';
import { client as braintree } from 'braintree-web';
import queryString from 'query-string';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {mpoScanner} from "../lib/Scanner";
import {
    isSafari,
    isMobileSafari,
    isLegacyEdge,
    isIE
} from "react-device-detect";
import {Helmet} from "react-helmet";
import {mpoFirebase} from "../lib/Firebase";
import PickupLocation from "../components/checkout/PickupLocation";
import {mpoApplePay} from "../lib/ApplePay";
import {ApplePayButton} from "react-apple-pay-button";
import {mpoGooglePay} from "../lib/GooglePay";
import GooglePayButton from "@google-pay/button-react";

const cacheMinsFulfilment = isProduction() ? -15 : -1;
const isCustomApp = process.env.REACT_APP_CUSTOM_APP === 'true';
const isSingleStore = isCustomApp && process.env.REACT_APP_SINGLE_STORE === 'true';
const displaySuburbName = process.env.REACT_APP_DISPLAY_SUBURB_NAME === 'true';
const scrollMarginTop = (process.env.REACT_APP_SCROLL_MARGIN_TOP !== undefined && process.env.REACT_APP_SCROLL_MARGIN_TOP !== null && process.env.REACT_APP_SCROLL_MARGIN_TOP !== "" ? process.env.REACT_APP_SCROLL_MARGIN_TOP : "60px");

let applePayAvailable = false;
let googlePayAvailable = false;

class Checkout extends Component {

    constructor(props) {
        //logger('checkout constructor');
        super(props);
        //logger(props);

        const fromLogin = props.location.state !== undefined && props.location.state.hasOwnProperty('fromSetCustomerAction') ? props.location.state.fromSetCustomerAction : false;
        let checkoutExtra = props.location.state !== undefined && props.location.state.hasOwnProperty('checkoutExtra') ? props.location.state.checkoutExtra : "";
        let showCheckout = checkoutExtra !== mpoFulfilment.fulfilmentTypeCodeDelivery;

        let deliveryAddressReqd = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let deliverySlotReqd = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let deliveryStopReqd = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions);
        let pickupLocationReqd = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions);
        if (deliveryStopReqd || (deliveryAddressReqd && (!this.isValidDeliveryAddress() || (deliverySlotReqd && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || checkoutExtra === mpoFulfilment.fulfilmentTypeCodeDelivery || fromLogin))) {
            showCheckout = false;
            checkoutExtra = mpoFulfilment.fulfilmentTypeCodeDelivery;
        } else if (pickupLocationReqd) {
            showCheckout = false;
            checkoutExtra = mpoFulfilment.fulfilmentTypeCodePickup;
        }

        const countryCodeOrCodes = this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                    this.props.merchant.locale.hasOwnProperty('country_code') && this.props.merchant.locale.country_code ?
                                    this.props.merchant.locale.country_code : ['AU','NZ'];

        this.state = {
            submittingOrder: false,
            placesSearchOptions: {
                componentRestrictions: {'country': countryCodeOrCodes},
                types: ['address']
            },
            coupon_code: this.props.cart.order.coupon_code,
            note: this.props.cart.order.note,
            info: null,
            delivery_address_manual: (this.props.user.checkout.delivery_address === "" && this.props.user.checkout.delivery_postcode !== "") || (this.props.user.checkout.delivery_address !== "" && this.props.user.checkout.delivery_postcode === ""),
            billing_address_manual: (this.props.user.checkout.billing_address === "" && this.props.user.checkout.billing_postcode !== "") || (this.props.user.checkout.billing_address !== "" && this.props.user.checkout.billing_postcode === ""),
            showCheckout: showCheckout,
            checkoutExtra: "",
        }

        this.onPromoCodeChange = this.onPromoCodeChange.bind(this);
        this.onOrderNoteChange = this.onOrderNoteChange.bind(this);

        this.fulfilmentModal = React.createRef();
        this.topOfCheckout = React.createRef();
    }

    componentDidMount = () => {
        //logger('checkout componentDidMount');
        mpoSentry.addBreadcrumb('nav','Checkout',Sentry.Severity.Info);
        this.loadCartPostDataHandler();
        if (!isGoogleMapsLoaded()) {
            mobiscroll.toast({message: 'Google maps not loaded, try again', color: 'danger'});
            mpoSentry.captureMessage('Google maps not loaded', Sentry.Severity.Warning);
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('Checkout componentDidUpdate');
        if (prevProps.fulfilmentType !== this.props.fulfilmentType && this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {
            //console.log(prevProps);
            //console.log(this.props);
            this.loadCartPostDataHandler();
        }
    }

    loadCartPostDataHandler = (showFulfilmentPopup = false) => {
        //console.log('Checkout loadCartPostDataHandler');
        //console.log(unixDatetimeToDate(this.props.fulfilmentOptions.datetime));
        //console.log(unixDatetimeToTime(this.props.fulfilmentOptions.datetime));

        if (this.props.merchant.id && 
            this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0 &&
            this.props.fulfilmentOptions !== undefined && Object.keys(this.props.fulfilmentType).length > 0) {

            mobiscroll.notification.dismiss();
            mobiscroll.toast({message: 'Loading checkout...', duration: 3000, display: 'center', color: 'info'});
            //logger(this.props.fulfilmentType);
            //logger(this.props.fulfilmentOptions);
            const checkout_options = {
                fulfilment_type: this.props.fulfilmentOptions.code,
                fulfilment_asap: this.props.fulfilmentOptions.asap ? 1 : 0,
                fulfilment_date: unixDatetimeToDate(this.props.fulfilmentOptions.datetime),
                fulfilment_time: unixDatetimeToTime(this.props.fulfilmentOptions.datetime),
            };

            if (this.props.user.checkout.delivery_address_id > 0) {
                checkout_options.delivery_address_id = this.props.user.checkout.delivery_address_id;
            } else if (this.props.user.checkout.delivery_address !== '') {
                const addr = this.props.user.checkout;
                checkout_options.delivery_address = addr.delivery_address1+','+addr.delivery_address2+','+addr.delivery_city+','+addr.delivery_state+','+addr.delivery_postcode+','+addr.delivery_country;
                checkout_options.delivery_address_str = addr.delivery_address;
            }

            const data = {
                RequestAction: 'ManageCart',
                sub_action: 'load_checkout',
                store_id: this.props.merchant.id,
                menu_id: this.props.merchant.menu_id, 
                menu_sub_type: this.props.fulfilmentType.menu_sub_type, //legacy
                checkout_options: checkout_options,
                component: 'Checkout'
            };

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    mobiscroll.notification.dismiss();
                    //logger(response);
                    //logger(this.props);
                    if (response.data.ResponseCode === "SUCCESS") {
                        
                        this.props.updateStateWithCart(response.data.Response);
                        //this.props.updateStateWithOrder(response.data.Response.order);
                        if (response.data.Response.hasOwnProperty('merchant')) {
                            this.props.updateStateWithMerchant(response.data.Response.merchant);
                        }

                        if (this.props.user.customer.id !== response.data.Response.customer.id && response.data.Response.customer.id === 0) {
                            this.props.updateStateWithCustomer({id: 0, status: 0});
                        } else {
                            if (this.props.user.checkout.custom_data.length !== response.data.Response.order.custom_data.length) {
                                this.props.updateStateWithCheckout({
                                    ...this.props.user.checkout,
                                    custom_data: response.data.Response.order.custom_data
                                });
                            }
                            if (this.props.user.checkout.billing_address_id === 0 && this.props.user.checkout.billing_address === '' && response.data.Response.order.billing_address_id > 0) {
                                this.props.updateStateWithCheckout({
                                    ...this.props.user.checkout,
                                    billing_address_id: response.data.Response.order.billing_address_id
                                });
                            }
                            if (this.props.user.checkout.delivery_address_id === 0 && this.props.user.checkout.delivery_address === '' && response.data.Response.order.delivery_address_id > 0) {
                                this.props.updateStateWithCheckout({
                                    ...this.props.user.checkout,
                                    delivery_address_id: response.data.Response.order.delivery_address_id
                                });
                            }
                            if (!this.props.user.checkout.hasOwnProperty('mobile_new') || this.props.user.checkout.mobile_new === null || this.props.user.checkout.mobile_new === "") {
                                this.props.updateStateWithCheckout({
                                    ...this.props.user.checkout,
                                    mobile_new: response.data.Response.customer.mobile
                                });
                                
                            }
                        }
                        //console.log(this.refs);
                        //this.refs.guestMobile.instance.setVal("0430 017 999",true, true);
                        //console.log(this.refs.guestMobile.instance.getVal())

                        //let menuSlug = this.props.match.params.slug;

                        if (this.state.info !== response.data.Response.menu.checkout_notice) {
                            this.setState({
                                //coupon_code: response.data.Response.order.coupon_code,
                                //note: response.data.Response.order.note,
                                info: response.data.Response.menu.checkout_notice,
                            });
                        }

                        if (parseInt(response.data.Response.merchant.is_apple_pay_enabled,10) === 1) {
                            applePayAvailable = mpoApplePay.isApplePayAvailable(this.props.merchant.id, response.data.Response.merchant.apple_pay_merchant_id, response.data.Response.merchant.name, response.data.Response.merchant.locale.country_code, response.data.Response.merchant.locale.currency_code);
                        } else {
                            applePayAvailable = false;
                        }

                        if (parseInt(response.data.Response.merchant.is_google_pay_enabled,10) === 1) {
                            googlePayAvailable = mpoGooglePay.isGooglePayAvailable(this.props.merchant.id, response.data.Response.merchant.google_pay_gateway, response.data.Response.merchant.google_pay_gateway_id, response.data.Response.merchant.google_pay_merchant_id, response.data.Response.merchant.name, response.data.Response.merchant.locale.country_code, response.data.Response.merchant.locale.currency_code);
                        } else {
                            googlePayAvailable = false;
                        }

                        if (response.data.Response.order.payment_method_id === 'PP' && this.props.location.search !== "") {
                            const ppValues = queryString.parse(this.props.location.search);
                            logger(ppValues);
                            if (ppValues.result) {
                                logger(ppValues.result);
                                mpoSentry.addBreadcrumb('checkout','PayPal ' + ppValues.result,Sentry.Severity.Info);

                                switch (ppValues.result) {
                                    case 'success':
                                        this.SubmitOrder();
                                        break;
                                    case 'cancel':
                                        //mobiscroll.toast({message: "PayPal payment cancelled", color: 'danger'});
                                        break;
                                    case 'error':
                                        let ppErrMsg = "PayPal: Unknown error PEC001";
                                        if (ppValues.msg && ppValues.msg !== "") {
                                            ppErrMsg = ppValues.msg;
                                        }
                                        mobiscroll.toast({message: ppErrMsg, color: 'danger'});
                                        break;
                                    default:
                                        mobiscroll.toast({message: "PayPal: Unknown result "+ppValues.result+' PRC001', color: 'danger'});
                                }
                            }
                        } else {
                            if (showFulfilmentPopup) {
                                this.showFulfilmentPopup();
                            }

                            mpoFirebase.LogEvent('begin_checkout', {
                                currency: this.props.merchant.locale.currency_code,
                                //items: response.data.Response.order.items.length,
                                value: response.data.Response.order.grand_total,
                            });
                        }

                    } else {
                        mobiscroll.toast({message: response.data.Response[0], duration: 10000, color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    }

                })
                .catch(error => {
                    mobiscroll.notification.dismiss();
                    //logger(error);
                    mobiscroll.toast({message: 'Error CH1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });
        } else {
            this.props.updateStateWithCart({
                order: {
                    items: []
                }
            });
        }
    }

    showFulfilmentPopup = () => {

        mobiscroll.notification.dismiss();

        mpoSentry.addBreadcrumb('nav','showFulfilmentPopup',Sentry.Severity.Info);

        let merchant = this.props.merchant;
        let fulfilment_types = this.props.merchant.fulfilment;

        if (fulfilment_types === undefined || fulfilment_types.length === 0 ||
            (merchant.hasOwnProperty('local_ts') && merchant.local_ts < unixUtcTimeOffsetMins(cacheMinsFulfilment))) {
            logger('showFulfilmentPopup: reload from server');
            this.loadCartPostDataHandler(true);
        } else if (fulfilment_types !== undefined && fulfilment_types.length > 0) {
            //console.log(this.fulfilmentModal);
            //console.log('All', fulfilment_types);
            //console.log('Cur', this.props.fulfilmentType);
            //console.log('Opt', this.props.fulfilmentOptions);

            let selectedFulfilmentType = null;
            let selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(null, true);

            if (this.props.fulfilmentType === undefined || Object.keys(this.props.fulfilmentType).length === 0) {
                //this.props.updateStateWithFulfilmentType(fulfilment_types[0]);
                if (fulfilment_types.length === 1) {
                    selectedFulfilmentType = fulfilment_types[0];
                }
            } else {
                selectedFulfilmentType = this.props.fulfilmentType;
                // override in case of menu availability changes
                for (let ft in fulfilment_types) {
                    if (this.props.fulfilmentType.id === fulfilment_types[ft].id) {
                        selectedFulfilmentType = fulfilment_types[ft];
                        break;
                    }
                }
            }
            //logger(selectedFulfilmentType);

            if (selectedFulfilmentType !== null) {
                if (this.props.fulfilmentOptions === undefined || Object.keys(this.props.fulfilmentOptions).length === 0) {
                    let selectedFulfilmentTypeCode = mpoFulfilment.fulfilmentTypePickup;
                    for (let code in selectedFulfilmentType.fulfilment_types) {
                        selectedFulfilmentTypeCode = code;
                        break;
                    }

                    const asap = parseInt(selectedFulfilmentType.fulfilment_types[selectedFulfilmentTypeCode].asap, 10) === 1 &&
                        parseInt(selectedFulfilmentType.availability.available_today, 10) === 1;

                    selectedFulfilmentOptions = mpoFulfilment.getDefaultFulfilmentOptions(selectedFulfilmentTypeCode, asap);
                    //this.props.updateStateWithFulfilmentOptions(selectedFulfilmentOptions);
                } else {
                    selectedFulfilmentOptions = this.props.fulfilmentOptions;
                }
            }

            if (this.fulfilmentModal.current !== null) {
                this.fulfilmentModal.current.setState({
                    merchant_id: merchant.id,
                    fulfilment_types: fulfilment_types,
                    selected_fulfilment_type: selectedFulfilmentType === null ? {} : selectedFulfilmentType,
                    selected_fulfilment_options: selectedFulfilmentOptions
                });

                this.fulfilmentModal.current.refs.popupFulfilment.instance.show();
            } else {
                // component unmounted?
                mpoSentry.captureException("this.fulfilmentModal.current === null (checkout)");
            }
        } else {
            mobiscroll.toast({message: "Fulfilment options not loaded, try again", color: 'danger'});
            mpoSentry.captureException("No fulfilment types (checkout)");
        }

    }

    updateCheckout = (checkout_options) => {

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_checkout',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            checkout_options: checkout_options,
            component: 'Checkout'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log('updateCheckout', checkout_options)
        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {
                if (response.data.Response.hasOwnProperty('additional_data') && response.data.Response.additional_data &&
                    response.data.Response.additional_data.hasOwnProperty('delivery_address') && response.data.Response.additional_data.delivery_address &&
                    response.data.Response.additional_data.delivery_address.hasOwnProperty('postcode_id') && response.data.Response.additional_data.delivery_address.postcode_id) {
                    const delivery_postcode_id = parseInt(response.data.Response.additional_data.delivery_address.postcode_id,0);
                    this.props.updateStateWithCheckout({
                        ...this.props.user.checkout,
                        delivery_postcode_id: delivery_postcode_id,
                        delivery_validated: delivery_postcode_id > 0,
                    });
                    //logger('delivery_postcode_id '+delivery_postcode_id);
                }
                // reload cart
                if (parseInt(response.data.Response.affects_cart_total, 10) === 1) {
                    this.loadCartPostDataHandler();
                }
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            logger(error);
            mobiscroll.toast({message: 'Error CH2, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    getPaymentNavItemsJsx = () => {

        return this.props.cart.menu.payment_methods.map((item) => {
            //console.log('item', item);
            if (item.code === 'APPLE' && !applePayAvailable) return;
            if (item.code === 'GOOGLE' && !googlePayAvailable) return;
            let icon = "empty icon fas fa-"+item.icon;
            return <mobiscroll.NavItem
                        id={item.id} key={item.id} 
                        icon={icon} 
                        isActive={() => this.props.cart.order.payment_method_id === item.code}
                        selected={this.props.cart.order.payment_method_id === item.code}
                        replace={true}
                        onClick={this.selectPaymentMethod.bind(null, item.code)}>
                        {item.desc}
                    </mobiscroll.NavItem>
        });

    }

    selectPaymentMethod = (selectedPaymentMethodCode) => {
        //console.log('selectPaymentMethod', selectedPaymentMethodCode);
        // send to server
        this.updateCheckout({
            payment_method: selectedPaymentMethodCode,
        })
    }

    setDeliveryAddressId = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address_id: parseInt(inst.getVal(),10),
            delivery_validated: false,
            delivery_postcode_id: 0,
        });
        // this.updateCheckout to validate address and get delivery fee
        this.updateCheckout({'delivery_address_id': parseInt(inst.getVal(),10)});
    }

    setBillingAddress = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            billing_address_id: parseInt(inst.getVal(),10)
        });
    }

    onSaveCardChange = (e) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            save_card: e.target.checked
        });
    }

    setStudentClass = (event, inst) => {
        //console.log(event, inst);
        //console.log(inst.getVal());
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            store_class_id: parseInt(inst.getVal(),10)
        });
    }

    onContactlessChange = (e) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            contactless: e.target.checked
        });
    }

    getContactlessContentJsx = (labelStr, descStr) => {
        return <mobiscroll.Checkbox checked={this.props.user.checkout.contactless} onChange={this.onContactlessChange}>
                    {labelStr}
                    {descStr ? <span className="mbsc-desc">{descStr}</span> : null}
                </mobiscroll.Checkbox>
    }

    getCustomFieldsContentJsx = (customData) => {
        //logger(customData);
        return Object.keys(customData).map(field => {
            //logger(customData[field]);
            return <CustomFieldItem
                key={field}
                field={field}
                value={this.props.user.checkout.custom_data[field]}
                settings={customData[field]}
                onChangeHandler={this.onCustomFieldChangeHandler}
            />
        } );
    }

    onCustomFieldChangeHandler = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = e.target.value;
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            custom_data: {
                ...this.props.user.checkout.custom_data,
                [fieldName]: fieldValue
            }
        });
        this.updateCheckout({
            custom_data: {
                [fieldName]: fieldValue
            }
        });
    }

    getPaymentSaveCardContentJsx = (selectedPaymentMethodCode) => {
        let saveCardJsx = null;
        if (selectedPaymentMethodCode === "CC") {
            if (this.props.user.auth.isMember) {
                saveCardJsx = <mobiscroll.FormGroup inset className="mpo-checkout-form">
                    <mobiscroll.Checkbox checked={this.props.user.checkout.save_card} onChange={this.onSaveCardChange}>
                    Save card to my account
                    <span className="mbsc-desc">We use a PCI compliant payment gateway to securely store and process credit/debit cards.</span>
                    </mobiscroll.Checkbox>
                </mobiscroll.FormGroup>
            }
        }
        return saveCardJsx;
    }

    getPaymentTabContentJsx = (selectedPaymentMethodCode) => {
        //console.log('getPaymentTabContentJsx', selectedPaymentMethodCode);
        let paymentTabContent;
        let ccNotice = '';
        let ccNotice2 = null;
        let ccNotice3 = null;
        //let noteColor = 'info';

        try {
            const currencySign = this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                    this.props.merchant.locale.hasOwnProperty('currency_sign') && this.props.merchant.locale.currency_sign ?
                                    this.props.merchant.locale.currency_sign : '';

            const selectedPaymentMethodSettings = this.props.cart.menu.payment_methods.filter((paymentMethod) => {
                return paymentMethod.code === selectedPaymentMethodCode;
            })[0];
            const selectedPaymentMethodExists = selectedPaymentMethodSettings && selectedPaymentMethodSettings !== null;
            const infoTextDiv = selectedPaymentMethodExists && selectedPaymentMethodSettings.hasOwnProperty('info') && selectedPaymentMethodSettings.info !== null && selectedPaymentMethodSettings.info.trim() !== "" ?
                (<div><span className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(selectedPaymentMethodSettings.info)}}></span></div>) : null;

            if (!selectedPaymentMethodExists) {
                logger("selectedPaymentMethodCode "+selectedPaymentMethodCode+" does not exist");
                logger(JSON.stringify(this.props.cart.menu.payment_methods));
                mpoSentry.captureMessage("selectedPaymentMethodCode "+selectedPaymentMethodCode+" does not exist", Sentry.Severity.Warning);
                selectedPaymentMethodCode = null;
            }

            switch (selectedPaymentMethodCode) {
                case 'CC':
                    const showBillingAddressDropdown = parseInt(this.props.merchant.is_billing_address_required,10) === 1 && this.props.user.auth.isLoggedIn && this.props.user.customer.billing_addresses.length > 0;
                    const showBillingAddressLookup = parseInt(this.props.merchant.is_billing_address_required,10) === 1 && (!showBillingAddressDropdown || this.props.user.checkout.billing_address_id === 0);
                    const showBillingAddressManualEntry = showBillingAddressLookup && (this.state.billing_address_manual || (this.props.user.checkout.billing_address === "" && this.props.user.checkout.billing_postcode !== ""));

                    let billingAddressDropdown = null;
                    if (showBillingAddressDropdown) {
                        billingAddressDropdown = <label>
                            Billing address
                            <mobiscroll.Select
                                select="single"
                                value={this.props.user.checkout.billing_address_id}
                                placeholder="Choose address or enter new one"
                                onSet={this.setBillingAddress}
                            >
                                <option key={0} value={0}>New billing address</option>
                                {this.props.user.customer.billing_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                            </mobiscroll.Select>
                        </label>
                    }

                    paymentTabContent = <div>
                        {infoTextDiv ? <div className="mbsc-padding" style={{paddingTop: "0"}}>{infoTextDiv}</div> : null}
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.props.user.checkout.card_name}
                            onChange={this.onCheckoutChange}
                            style={{textTransform: "upperCase"}}
                            name="cc-name"
                            autoComplete="cc-name"
                            data-fieldname="card_name">Name on Card</mobiscroll.Input>

                        {/*
                        <mobiscroll.Numpad
                            fill="ltr"
                            template="dddd dddd dddd dddd"
                            allowLeadingZero={true}
                            validate={validateCardNumber}
                            placeholder=""
                            showOnFocus={true}
                            onSet={this.onCardNumberChange}>
                            <mobiscroll.Input
                                labelStyle="stacked"
                                value={this.props.user.checkout.card_number}
                                onChange={this.onCheckoutChange}
                                name="cc-number"
                                autoComplete="cc-number"
                                data-fieldname="card_number">Card Number</mobiscroll.Input>
                        </mobiscroll.Numpad>
                        */}
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.props.user.checkout.card_number}
                            onChange={this.onCheckoutChange}
                            name="cc-number"
                            type="number"
                            autoComplete="cc-number"
                            maxLength={20}
                            //pattern="^[0-9]{13,16}$"
                            data-fieldname="card_number">Card Number</mobiscroll.Input>

                        <label>
                        Card Expiry
                            <mobiscroll.Date
                            labelStyle="stacked"
                                value={this.props.user.checkout.card_expiry_date}
                                min={new Date()}
                                max={new Date(2099, 11, 1)}
                                dateFormat="mm/yyyy"
                                //returnFormat="locale"
                                name="cc-exp"
                                autoComplete="cc-exp"
                                showOnFocus={true}
                                onSet={this.onCardExpiryChange}
                                />
                        </label>
                        {/*
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.props.user.checkout.card_expiry}
                            onChange={this.onCheckoutChange}
                            name="cc-exp"
                            type="number"
                            autoComplete="cc-exp"
                            maxLength={7}
                            placeHolder="mm/yyyy"
                            //pattern="^(0[1-9]|1[0-2])\/([0-9]{4})$"
                            data-fieldname="card_expiry">Card Expiry</mobiscroll.Input>
                        */}

                        <mobiscroll.Numpad
                            fill="ltr"
                            template="dddd"
                            allowLeadingZero={true}
                            validate={validateCardCSC}
                            showOnFocus={true}
                            placeholder=""
                            onSet={this.onCardCSCChange}>
                            <mobiscroll.Input
                                labelStyle="stacked"
                                value={this.props.user.checkout.card_csc}
                                onChange={this.onCheckoutChange}
                                name="cc-csc"
                                autoComplete="cc-csc"
                                data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>
                        </mobiscroll.Numpad>
                        {/*
                        <mobiscroll.Input
                            labelStyle="stacked"
                            value={this.props.user.checkout.card_csc}
                            onChange={this.onCheckoutChange}
                            name="cc-csc"
                            type="number"
                            autoComplete="cc-csc"
                            maxLength={4}
                            placeHolder="3 or 4 digits on back of card"
                            //pattern="^[0-9]{3,4}$"
                            data-fieldname="card_csc">Security Code (CVC)</mobiscroll.Input>
                        */}

                        {billingAddressDropdown}

                        {showBillingAddressLookup ?
                        <React.Fragment>
                            <PlacesAutocomplete
                                value={this.props.user.checkout.billing_address}
                                onChange={this.onBillingAddressChange}
                                onSelect={this.onBillingAddressSelect}
                                onError={this.onBillingAddressError}
                                searchOptions={this.state.placesSearchOptions}
                                shouldFetchSuggestions={this.props.user.checkout.billing_address.length > 3}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <React.Fragment>
                                    <mobiscroll.Input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                        labelStyle="stacked"
                                        placeholder="Start typing your address here"
                                        type="text"
                                        name="searchBillingAddress">{showBillingAddressDropdown ? "New billing address" : "Billing address"}</mobiscroll.Input>
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div className="suggestion-item">Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                        //console.log(suggestion);
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </React.Fragment>
                                )}
                            </PlacesAutocomplete>
                            {showBillingAddressManualEntry ?
                            <React.Fragment>
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.billing_address1} onChange={this.onBillingAddressManualChange} name="billingAddress1" data-fieldname="billing_address1" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line1"}>{this.props.merchant.locale.labels.address1}</mobiscroll.Input>
                                {(this.props.merchant.locale.labels.hasOwnProperty('address2') && this.props.merchant.locale.labels.address2 !== '') ?
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.billing_address2} onChange={this.onBillingAddressManualChange} name="billingAddress2" data-fieldname="billing_address2" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line2"}>{this.props.merchant.locale.labels.address2}</mobiscroll.Input>
                                : null}
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.billing_city} onChange={this.onBillingAddressManualChange} name="billingCity" data-fieldname="billing_city" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level2"}>{this.props.merchant.locale.labels.city_suburb}</mobiscroll.Input>
                                {(this.props.merchant.locale.labels.hasOwnProperty('state') && this.props.merchant.locale.labels.state !== '') ?
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.billing_state} onChange={this.onBillingAddressManualChange} name="billingState" data-fieldname="billing_state" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level1"}>{this.props.merchant.locale.labels.state}</mobiscroll.Input>
                                : null}
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.billing_postcode} onChange={this.onBillingAddressManualChange} name="billingPostcode" data-fieldname="billing_postcode" autoComplete={this.props.user.auth.isStaff ? "off" : "postal-code"}>{this.props.merchant.locale.labels.postcode}</mobiscroll.Input>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="mbsc-padding" style={{paddingTop: "0.5em", paddingBottom: "0"}}><span className="mbsc-txt-s" style={{textTransform: "none"}}><a href="#" onClick={(e) => { e.preventDefault(); this.updateBillingAddressManual(true); }}>Enter address manually instead</a></span></div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                        : null }
                    </div>;
                    break;
                case 'SAVED':
                    if (!this.props.user.auth.isLoggedIn || this.props.user.auth.isGuest) {
                        ccNotice = <span className="mbsc-txt-s">Register for a FREE account to securely save your credit/debit card and speed up subsequent purchases. We use a PCI compliant payment gateway to securely store and process credit/debit cards.</span>;
                        // add register link
                    } else {
                        if (this.props.cart.customer.has_saved_card) {
                            ccNotice = '';
                            if (this.props.cart.customer.credit_card_expired === 1) {
                                //ccNotice = 'Your saved credit card has expired.'
                            } else if ((this.props.cart.customer.credit_card_type !== '') && (this.props.cart.customer.credit_card_ending !== '')) {
                                ccNotice = 'You have chosen to pay with your '+this.props.cart.customer.credit_card_type+' ending '+this.props.cart.customer.credit_card_ending;
                                if ((this.props.cart.customer.credit_card_type === 'AMEX' && this.props.merchant.surcharge_percent_amex > 0) ||
                                    (this.props.cart.customer.credit_card_type !== 'AMEX' && this.props.merchant.surcharge_percent_cc > 0)) {
                                    ccNotice += '. A small surcharge may apply';
                                }
                                ccNotice += '.';
                            } else {
                                ccNotice = 'You have chosen to pay with your saved credit/debit card.';
                            }
                            if (ccNotice !== '') {
                                ccNotice = <span className="mbsc-txt-s">{ccNotice}</span>;
                            }

                            if (this.props.cart.customer.credit_card_notice !== '') {
                                ccNotice2 = <mobiscroll.Note color="danger">{this.props.cart.customer.credit_card_notice}</mobiscroll.Note>
                            }
                            if (parseInt(this.props.cart.customer.credit_card_update,10) === 1) {
                                ccNotice3 = <mobiscroll.Note color="info">Use the New Card option now and tick the box to securely save the card before placing your order. We use a PCI compliant payment gateway to securely store and process credit/debit cards.</mobiscroll.Note>
                            }
                        } else {
                            ccNotice = <mobiscroll.Note color="info">You do not have a saved credit/debit card. Use the New Card option now and tick the box to securely save the card before placing your order. We use a PCI compliant payment gateway to securely store and process credit/debit cards.</mobiscroll.Note>
                        }
                    }

                    paymentTabContent = <div>
                        {ccNotice}
                        {ccNotice2}
                        {ccNotice3}
                        {infoTextDiv}
                    </div>;
                    break;

                case 'ACCT':
                    if (!this.props.user.auth.isLoggedIn || this.props.user.auth.isGuest) {
                        ccNotice = 'Register for a FREE account to add funds and speed up subsequent purchases.';
                        // add register link
                    } else {
                        if (parseFloat(this.props.cart.order.grand_total) > parseFloat(this.props.cart.customer.balance)) {
                            if ((this.props.cart.customer.has_saved_card) && (parseInt(this.props.cart.customer.credit_card_update, 10) === 0)) {
                                let saved_card_text = 'saved credit/debit card';
                                if ((this.props.cart.customer.credit_card_type !== '') && (this.props.cart.customer.credit_card_ending !== '')) {
                                    saved_card_text = this.props.cart.customer.credit_card_type+' ending '+this.props.cart.customer.credit_card_ending;
                                }
                                if (parseFloat(this.props.cart.customer.auto_topup_amount) > 0) {
                                    ccNotice = 'You have chosen to pay for this order using your account balance. Your ' + saved_card_text + ' will be used to add funds to your account.';
                                } else {
                                    ccNotice = <span>You do not have sufficient funds in your account balance to pay for this order. Pay using your <a href="#" onClick={(e) => { e.preventDefault(); this.selectPaymentMethod("SAVED"); }}>Saved Card ({saved_card_text})</a> or <a href="#" onClick={(e) => { e.preventDefault(); this.selectPaymentMethod("CC"); }}>Add a New Card</a>.</span>
                                }
                            } else {
                                ccNotice = 'You do not have sufficient funds in your account balance to pay for this order. Top up your account or use the New Card option now and optionally save your card to select your preferred topup options. We use a PCI compliant payment gateway to securely store and process credit/debit cards.';
                            }
                        } else {
                            if ((this.props.cart.customer.has_saved_card) && (this.props.cart.customer.credit_card_notice !== '')) {
                                ccNotice = 'You have chosen to pay for this order using your account balance.';
                            } else {
                                ccNotice = 'You have chosen to pay for this order using your account balance.';
                            }
                        }
                    }

                    paymentTabContent = <div>
                        <div style={{paddingBottom: "0.5em"}}>
                            <span className="mbsc-txt-s">Account balance</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.customer.balance).toFixed(2)}</span>
                        </div>
                        <span className="mbsc-txt-s">{ccNotice}</span>
                        {infoTextDiv}
                    </div>;
                    break;

                case 'PP':
                    if (isIframe()) {
                        ccNotice = 'PayPal is unavailable from within an iFrame, please choose another payment method.';
                    } else {
                        ccNotice = 'You have chosen to pay with PayPal.';
                        if (this.props.merchant.surcharge_percent_paypal > 0) {
                            ccNotice += ' A small surcharge may apply.';
                        }
                        ccNotice += ' Click Check Out with PayPal to proceed.';
                    }
                    ccNotice = <span className="mbsc-txt-s">{ccNotice}</span>

                    paymentTabContent = <div>
                        {ccNotice}<br/>
                        {infoTextDiv}
                    </div>;
                    break;

                case 'CB':
                    if (isIframe()) {
                        paymentTabContent = <div>
                            <span className="mbsc-txt-s">Cryptocurrency payments are unavailable from within an iFrame, please choose another payment method.</span>
                        </div>;
                    } else {
                        // todo: only allow if fulfilment time an hour or more in the future
                        paymentTabContent = infoTextDiv;
                    }
                    break;

                case 'APPLE':
                    if (applePayAvailable) {
                        paymentTabContent = infoTextDiv;
                    } else {
                        paymentTabContent = <div>
                            <span className="mbsc-txt-s">Apple Pay is currently unavailable, please choose another payment method.</span>
                        </div>;
                    }
                    break;

                case 'GOOGLE':
                    if (googlePayAvailable) {
                        paymentTabContent = infoTextDiv;
                    } else {
                        paymentTabContent = <div>
                            <span className="mbsc-txt-s">Google Pay is currently unavailable, please choose another payment method.</span>
                        </div>;
                    }
                    break;

                case 'instore':
                case 'BANK':
                case 'INV':
                case 'ZERO_DUE':
                    paymentTabContent = infoTextDiv;
                    break;

                default:
                    paymentTabContent = <div>
                        <span className="mbsc-txt-s">Please choose a payment method.</span>
                    </div>;
            }
        } catch (error) {
            mpoSentry.captureException(error);
            paymentTabContent = <div>
                <span className="mbsc-txt-s">Please choose a payment method.</span>
            </div>;
        }

        return paymentTabContent;

    }

    onGuestChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        this.props.updateStateWithGuest({
            ...this.props.user.guest,
            [fieldName]: e.target.value
        });
    }

    onGuestMobileChange = (e, inst) => {
        this.props.updateStateWithGuest({
            ...this.props.user.guest,
            mobile: e.valueText
        });
    }

    onCheckoutMobileChange = (e, inst) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            mobile_new: e.valueText
        });
    }

    onCheckoutChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'card_name' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onCheckoutChange '+fieldName+'='+fieldValue);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            [fieldName]: fieldValue
        });
    }

    onCardNumberChange = (e, inst) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            card_number: e.valueText
        });
    }

    onCardExpiryChange = (e, inst) => {
        //console.log('onCardExpiryChange', e, inst);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            card_expiry: e.valueText,
            card_expiry_date: inst.getVal()
        });
    }

    onCardCSCChange = (e, inst) => {
        //logger('onCardCSCChange card_csc='+e.valueText);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            card_csc: e.valueText
        });
    }

    onDeliveryAddressChange = (address) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address: address,
            delivery_geo: {},
            delivery_latlng: {},
            delivery_address_id: 0,
            delivery_address1: "",
            delivery_address2: "",
            delivery_city: "",
            delivery_state: "",
            delivery_postcode: "",
            delivery_country: "",
            delivery_validated: false,
            delivery_postcode_id: 0,
        });
    }

    onDeliveryAddressManualChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'delivery_state' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onDeliveryAddressManualChange '+fieldName+'='+fieldValue);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            delivery_address_id: 0,
            delivery_address: "",
            delivery_geo: {},
            delivery_latlng: {},
            delivery_country: "",
            delivery_validated: false,
            delivery_postcode_id: 0,
            [fieldName]: fieldValue
        });
    }

    onDeliveryAddressSelect = (address) => {
        //console.log(address);
        this.onDeliveryAddressChange(address);
        if (address === null || address === "") {
            return;
        }
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    delivery_address_id: 0,
                    delivery_address1: "",
                    delivery_address2: "",
                    delivery_city: "",
                    delivery_state: "",
                    delivery_postcode: "",
                    delivery_country: "",
                    delivery_geo: {},
                    delivery_latlng: {},
                    delivery_validated: false,
                    delivery_postcode_id: 0,
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.delivery_geo = results[0];
                    let has_street_num = false;
                    let has_postcode = false;
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            for(var i = 0; i < a['types'].length; i++) {
                                switch (a['types'][i]) {
                                    case 'street_number':
                                        has_street_num = true;
                                    case 'route':
                                        if (addr.delivery_address1 !== "") {
                                            addr.delivery_address1 += " ";
                                        }
                                        addr.delivery_address1 += a['short_name'];
                                        break;
                                    case 'locality':
                                        addr.delivery_city = a['short_name'];
                                        break;
                                    case 'administrative_area_level_1':
                                        addr.delivery_state = a['short_name'];
                                        break;
                                    case 'postal_code':
                                        has_postcode = true;
                                        addr.delivery_postcode = a['short_name'];
                                        break;
                                    case 'country':
                                        addr.delivery_country = a['short_name'];
                                        break;
                                }
                            }
                        }
                    }
                    if (!has_street_num) {
                        //logger('NO STREET NUMBER');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO STREET NUMBER', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming street address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else if (!has_postcode) {
                        //logger('NO POST CODE');
                        //logger('daddr1: '+addr.delivery_address1);
                        //logger('daddrStr: '+address);
                        mpoSentry.captureMessage('NO POST CODE', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateDeliveryAddressManual(true);
                    } else {
                        // if billing address not yet entered then set to same as delivery address
                        let updateBillingAddress = false;
                        if (this.props.user.checkout.billing_address === "" && this.props.user.checkout.billing_address1 === "" && this.props.user.checkout.billing_address_id <= 0) {
                            updateBillingAddress = true;
                            addr.billing_address = address;
                            addr.billing_geo = addr.delivery_geo;
                            addr.billing_latlng = {};
                            addr.billing_address1 = addr.delivery_address1;
                            addr.billing_address2 = addr.delivery_address2;
                            addr.billing_city = addr.delivery_city;
                            addr.billing_state = addr.delivery_state;
                            addr.billing_postcode = addr.delivery_postcode;
                            addr.billing_country = addr.delivery_country;
                        }

                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        
                        // this.updateCheckout to get delivery fee
                        this.updateCheckout({'delivery_address': addr.delivery_address1+','+addr.delivery_address2+','+addr.delivery_city+','+addr.delivery_state+','+addr.delivery_postcode+','+addr.delivery_country});
                        
                        getLatLng(results[0])
                            .then(latLng => {
                                //console.log('Success2', latLng);
                                const updatedState = {
                                    delivery_latlng: latLng
                                }
                                if (updateBillingAddress) {
                                    updatedState.billing_latlng = latLng;
                                }
                                this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, updatedState));
                            })
                            .catch(error => {
                                //console.error('Error2', error)
                                mpoSentry.captureException(error);
                            });
                    }
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
                mobiscroll.toast({message: "Error searching for address. Please enter your delivery address manually.", duration: 6000, color: 'danger'});
                this.updateDeliveryAddressManual(true);
            });
    }

    onBillingAddressChange = (address) => {
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            billing_address: address,
            billing_geo: {},
            billing_latlng: {},
            billing_address1: "",
            billing_address2: "",
            billing_city: "",
            billing_state: "",
            billing_postcode: "",
            billing_country: "",
        });
    }

    onBillingAddressManualChange = (e) => {
        let fieldName = e.target.getAttribute('data-fieldname');
        let fieldValue = fieldName === 'billing_state' ? e.target.value.toUpperCase() : e.target.value;
        //logger('onBillingAddressManualChange '+fieldName+'='+fieldValue);
        this.props.updateStateWithCheckout({
            ...this.props.user.checkout,
            billing_address: "",
            billing_geo: {},
            billing_latlng: {},
            billing_country: "",
            [fieldName]: fieldValue
        });
    }

    onBillingAddressSelect = (address) => {
        //console.log(address);
        this.onBillingAddressChange(address);
        if (address === null || address === "") {
            return;
        }
        geocodeByAddress(address)
            .then(results => {
                //console.log('geo', results); 
                const addr = {
                    billing_geo: {},
                    billing_latlng: {},
                    billing_address1: "",
                    billing_address2: "",
                    billing_city: "",
                    billing_state: "",
                    billing_postcode: "",
                    billing_country: "",
                };
                if (results !== undefined && results[0] !== undefined) {
                    addr.billing_geo = results[0];
                    let has_street_num = false;
                    let has_postcode = false;
                    if (results[0].address_components !== undefined) {
                        for (let a of results[0].address_components) {
                            for(var i = 0; i < a['types'].length; i++) {
                                switch (a['types'][0]) {
                                    case 'street_number':
                                        has_street_num = true;
                                    case 'route':
                                        if (addr.billing_address1 !== "") {
                                            addr.billing_address1 += " ";
                                        }
                                        addr.billing_address1 += a['short_name'];
                                        break;
                                    case 'locality':
                                        addr.billing_city = a['short_name'];
                                        break;
                                    case 'administrative_area_level_1':
                                        addr.billing_state = a['short_name'];
                                        break;
                                    case 'postal_code':
                                        addr.billing_postcode = a['short_name'];
                                        has_postcode = true;
                                        break;
                                    case 'country':
                                        addr.billing_country = a['short_name'];
                                        break;
                                }
                            }
                        }
                    }

                    if (!has_street_num) {
                        //logger('NO STREET NUMBER');
                        //logger('baddr1: '+addr.billing_address1);
                        //logger('baddrStr: '+address);
                        mpoSentry.captureMessage('NO STREET NUMBER', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming street address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateBillingAddressManual(true);
                    } else if (!has_postcode) {
                        //logger('NO POST CODE');
                        //logger('baddr1: '+addr.billing_address1);
                        //logger('baddrStr: '+address);
                        mpoSentry.captureMessage('NO POST CODE', Sentry.Severity.Warning);
                        mobiscroll.toast({message: "Error confirming address, please enter it manually", duration: 6000, color: 'danger'});
                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));
                        this.updateBillingAddressManual(true);
                    } else {

                        this.props.updateStateWithCheckout(updateObject(this.props.user.checkout, addr));

                        getLatLng(results[0])
                            .then(latLng => {
                                //console.log('Success2', latLng)
                                this.props.updateStateWithCheckout({
                                    ...this.props.user.checkout,
                                    billing_latlng: latLng
                                });
                            })
                            .catch(error => {
                                //console.error('Error2', error)
                                mpoSentry.captureException(error);
                            });

                    }
                }
            })
            .catch(error => {
                //console.error('Error1', error)
                mpoSentry.captureException(error);
                mobiscroll.toast({message: "Error searching for address. Please enter your billing address manually.", duration: 6000, color: 'danger'});
                this.updateBillingAddressManual(true);
            });
    }

    onDeliveryAddressError = (status, clearSuggestions) => {
        mpoSentry.captureMessage('Google Maps API returned error with status: '+status, Sentry.Severity.Warning);
        clearSuggestions();
        mobiscroll.toast({message: "Error searching for address. Please enter your delivery address manually.", duration: 6000, color: 'danger'});
        this.updateDeliveryAddressManual(true);
    }

    onBillingAddressError = (status, clearSuggestions) => {
        mpoSentry.captureMessage('Google Maps API returned error with status: '+status, Sentry.Severity.Warning);
        clearSuggestions();
        mobiscroll.toast({message: "Error searching for address. Please enter your billing address manually.", duration: 6000, color: 'danger'});
        this.updateBillingAddressManual(true);
    }

    _wallet_payment_callback = (success, wallet_data, wallet, amount) => {

        if (success) {
            // send to backend to process payment and place order
            this.SubmitOrder(false, wallet_data);
        } else {
            if (wallet_data) {
                mobiscroll.toast({message: wallet_data, color: 'danger'});
            }
        }

    }

    validateCheckoutFormFields = (silent = false) => {

        let errorMsg = '';

        if (!this.props.user.auth.isLoggedIn || this.props.user.auth.isGuest || this.props.user.auth.isStaff) {
            // guest checkout or staff placing order on behalf of a customer
            if (this.props.user.guest.name === null || this.props.user.guest.name.trim() === '') {
                errorMsg = 'Enter your name for the order';
            } else if (this.props.user.guest.email === null || this.props.user.guest.email.trim() === '') {
                errorMsg = 'Enter your email address for your receipt';
            } else if (this.props.user.guest.mobile === null || this.props.user.guest.mobile.trim() === '') {
                errorMsg = 'Please enter your mobile phone number';
            }
        } else {
            // customer is logged in with a member account
            // require mobile number if not one already on the customer's account
            if ((this.props.user.customer.mobile === null || this.props.user.customer.mobile.trim() === '') &&
                (this.props.user.checkout.mobile_new === null || this.props.user.checkout.mobile_new.trim() === '')) {
                errorMsg = 'Please enter your mobile phone number';
            }
        }

        if (errorMsg !== '') {
            mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
            this.scrollToTopOfCheckout();
            return false;
        }

        return true;

    }

    SubmitOrder = (validateOnly = false, nonce = null) => {

        // leave this validation to the server
        let showFulfilmentPopup = false; //!this.state.submittingOrder && !mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions);

        if (showFulfilmentPopup) {

            mobiscroll.snackbar({
                message: 'Your requested time has past. Please choose another time.',
                color: 'danger',
                duration: false,
                button: {
                    text: 'Change Time',
                    icon: 'clock',
                    action: (p) => {
                        this.showFulfilmentPopup();
                    }
                }
            });

        } else if (this.props.cart.order.payment_method_id === 'PP' && isIframe()) {

            mobiscroll.toast({message: "PayPal is unavailable from within an iFrame, please choose another payment method.", duration: 6000, color: 'danger'});

        } else {

            // run some minimal validation for required fields
            const clientSideValidationPaymentTypes = ['PP','APPLE','GOOGLE'];
            if (clientSideValidationPaymentTypes.indexOf(this.props.cart.order.payment_method_id) !== -1) {
                if (!this.validateCheckoutFormFields()) {
                    return false;
                }
            }

            if (this.props.cart.order.payment_method_id === 'APPLE' && nonce === null) {
                // launch apple pay sheet to get payment data
                mpoApplePay.commencePayment(this.props.cart.order.grand_total, 'Order payment', this._wallet_payment_callback);
                return;
            }

            if (this.props.cart.order.payment_method_id === 'GOOGLE' && nonce === null) {
                // launch google pay sheet to get payment data
                mpoGooglePay.commencePayment(this.props.cart.order.grand_total, 'Order payment', this._wallet_payment_callback);
                return;
            }

            // can be returning from PayPal Checkout
            if (!this.state.submittingOrder) {
                this.setState({
                    submittingOrder: true
                });
            }

            const isBraintreeEnabled = parseInt(this.props.merchant.is_braintree_payments_enabled,10) === 1;
            if (this.props.cart.order.payment_method_id === 'CC' && isBraintreeEnabled && nonce === null) {
                braintree.create({
                    authorization: this.props.merchant.braintree_tokenization_key
                }, (err, client) => {
                    logger(err);
                    //logger(client);
                    if (client) {
                        let billing_postcode = this.props.user.checkout.billing_postcode;
                        if (parseInt(this.props.user.checkout.billing_address_id,10) > 0 && this.props.user.customer.billing_addresses.length > 0) {
                            for (var j = 0; j < this.props.user.customer.billing_addresses.length; j++) {
                                if (parseInt(this.props.user.customer.billing_addresses[j].id,10) === parseInt(this.props.user.checkout.billing_address_id,10)) {
                                    billing_postcode = this.props.user.customer.billing_addresses[j].postcode;
                                    logger(billing_postcode);
                                }
                            }
                        }
                        client.request({
                            endpoint: 'payment_methods/credit_cards',
                            method: 'post',
                            data: {
                                creditCard: {
                                    number: this.props.user.checkout.card_number,
                                    expirationDate: this.props.user.checkout.card_expiry,
                                    cvv: this.props.user.checkout.card_csc,
                                    billingAddress: {
                                        postalCode: billing_postcode
                                    }
                                }
                            }
                        }, (err, response) => {
                            logger(err);
                            //logger(response);
                            if (err) {
                                mobiscroll.toast({message: "Error contacting Braintree Payments", duration: 6000, color: 'danger'});
                            } else {
                                // Send response.creditCards[0].nonce to your server
                                this.SubmitOrder(false, response.creditCards[0].nonce);
                            }
                        });
                    }
                });
                return;
            }

            mpoSentry.addBreadcrumb('checkout','SubmitOrder',Sentry.Severity.Info);

            const data = {
                RequestAction: 'SubmitOrder',
                merchant_id: this.props.merchant.id,
                menu_id: this.props.cart.menu.menu_id,
                preorder: !validateOnly,
                fulfilment_type: this.props.fulfilmentOptions.code,
                fulfilment_asap: this.props.fulfilmentOptions.asap,
                fulfilment_datetime: this.props.fulfilmentOptions.datetime !== "" && parseInt(this.props.fulfilmentOptions.datetime, 10) > 0 ? this.props.fulfilmentOptions.datetime/1000 : null,
                contact_name: this.props.fulfilmentOptions.hasOwnProperty('dineinBookingName') ? this.props.fulfilmentOptions.dineinBookingName : "",
                booking_ref: this.props.fulfilmentOptions.hasOwnProperty('dineinBookingRef') ? this.props.fulfilmentOptions.dineinBookingRef : "",
                booking_pax: this.props.fulfilmentOptions.hasOwnProperty('dineinBookingPax') ? this.props.fulfilmentOptions.dineinBookingPax : 0,
                table_num: this.props.fulfilmentOptions.hasOwnProperty('dineinTableNum') ? this.props.fulfilmentOptions.dineinTableNum : "",
                customer_name: this.props.user.guest.name,
                customer_email: this.props.user.guest.email,
                customer_mobile: this.props.user.guest.mobile,
                customer_mobile_new: this.props.user.checkout.mobile_new,
                student_name: this.props.user.checkout.student_name,
                store_class_id: this.props.user.checkout.store_class_id,
                payment_method: this.props.cart.order.payment_method_id,
                card_name: this.props.user.checkout.card_name,
                card_number: this.props.user.checkout.card_number,
                card_expiry: this.props.user.checkout.card_expiry,
                card_csc: this.props.user.checkout.card_csc,
                delivery_address_id: this.props.user.checkout.delivery_address_id,
                delivery_address_str: this.props.user.checkout.delivery_address,
                delivery_address1: this.props.user.checkout.delivery_address1,
                delivery_address2: this.props.user.checkout.delivery_address2,
                delivery_city: this.props.user.checkout.delivery_city,
                delivery_state: this.props.user.checkout.delivery_state,
                delivery_postcode: this.props.user.checkout.delivery_postcode,
                delivery_country: this.props.user.checkout.delivery_country,
                delivery_geo: this.props.user.checkout.delivery_geo,
                delivery_latlng: this.props.user.checkout.delivery_latlng,
                delivery_slot_id: this.props.fulfilmentOptions.hasOwnProperty('deliverySlotId') ? this.props.fulfilmentOptions.deliverySlotId : 0,
                delivery_stop_id: this.props.fulfilmentOptions.hasOwnProperty('deliveryStopId') ? this.props.fulfilmentOptions.deliveryStopId : 0,
                pickup_location_id: this.props.fulfilmentOptions.hasOwnProperty('pickupLocationId') ? this.props.fulfilmentOptions.pickupLocationId : 0,
                billing_address_id: this.props.user.checkout.billing_address_id,
                billing_address_str: this.props.user.checkout.billing_address,
                billing_address1: this.props.user.checkout.billing_address1,
                billing_address2: this.props.user.checkout.billing_address2,
                billing_city: this.props.user.checkout.billing_city,
                billing_state: this.props.user.checkout.billing_state,
                billing_postcode: this.props.user.checkout.billing_postcode,
                billing_country: this.props.user.checkout.billing_country,
                billing_geo: this.props.user.checkout.billing_geo,
                billing_latlng: this.props.user.checkout.billing_latlng,
                //customer_ref: null,
                save_card: this.props.user.checkout.save_card ? 1 : 0,
                auto_topup: this.props.user.checkout.auto_topup ? 1 : 0,
                //auto_topup_amount: ($('#checkout_topup_amount').val() === 'other') ? $('#checkout_topup_amount_other').val() : $('#checkout_topup_amount').val(),
                contactless: this.props.user.checkout.contactless ? 1 : 0,
                custom_data: this.props.user.checkout.custom_data,
                order_note: this.props.cart.order.note,
                order_note_included: 1,
                order_source: isCordova() ? "rapp" : "rmobi", //rmobi, rweb, rapp, fbrmobi, fbrweb, fbrapp
                wallet_data: nonce,
                braintree_nonce: isBraintreeEnabled ? nonce : null
            };

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            mobiscroll.notification.dismiss();
            if (validateOnly) {
                mobiscroll.toast({message: 'Validating order...', duration: 10000, display: 'center', color: 'info'});
            } else {
                mobiscroll.toast({message: 'Placing order...', duration: 15000, display: 'center', color: 'info'});
            }

            logger(data);
            axiosInstance.post(null, data)
            .then(response => {
                logger(response);
                mobiscroll.notification.dismiss();
                if (response.data.ResponseCode === "SUCCESS") {
                    if (response.data.Response.hasOwnProperty('payment_method') && response.data.Response.payment_method === "PP" &&
                        response.data.Response.hasOwnProperty('payment_redirect') && response.data.Response.payment_redirect !== "") {

                        mpoSentry.addBreadcrumb('checkout','PayPal redirect',Sentry.Severity.Info);
                        //logger(response.data.Response.payment_redirect);
        
                        if (isCordova()) {
                            logger('pg app - open inapp browser');
                            mpoPayPal._inapp_browser_result = null;
                            mpoPayPal._inapp_browser = openWindow(response.data.Response.payment_redirect, '_blank', 'location=no', isCustomApp);
                            mpoPayPal._inapp_browser.addEventListener('loadstop', mpoPayPal._in_app_browser_load_stop);
                            mpoPayPal._inapp_browser.addEventListener('loaderror', mpoPayPal._in_app_browser_load_error);
                            mpoPayPal._inapp_browser.addEventListener('exit', this._in_app_browser_exit);            
                        } else {
                            logger('web app - open in current window');
                            mobiscroll.notification.dismiss();
                            mobiscroll.toast({message: "Contacting PayPal (patience)...", duration: 15000, display: 'center', color: 'info'});
                            let returnurl = window.location.protocol+'//'+window.location.hostname+'/#'+this.props.history.location.pathname;
                            if (process.env.NODE_ENV !== 'production') {
                                if (window.location.port !== 80 && window.location.port !== 443) {
                                    returnurl = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+'/#'+this.props.history.location.pathname;
                                }
                            }
                            returnurl = encodeURIComponent(returnurl);
                            logger(returnurl);
                            const cancelurl = returnurl;
                            const errorurl = returnurl;
                            const paypalurl = response.data.Response.payment_redirect+'&returnurl='+returnurl+'&cancelurl='+cancelurl+'&errorurl='+errorurl;
                            logger(paypalurl);
                            window.location.replace(paypalurl);
                        }
                    } else {
                        if (this.props.cart.order.payment_method_id === 'APPLE') {
                            mpoApplePay.successPayment();
                        }
                        mobiscroll.toast({message: 'Thank you for your order', color: 'success'});
                        const orderId = response.data.Response.order_id;
                        try {
                            mpoFirebase.LogEvent('purchase', {
                                //affiliation:
                                coupon: response.data.Response.promo_code,
                                currency: this.props.merchant.locale.currency_code,
                                //items:
                                transaction_id: orderId,
                                //shipping:
                                //tax:
                                value: response.data.Response.order_total
                            });

                            this.props.updateStateWithCart({
                                order: {
                                    items: []
                                }
                            });
                            this.resetFulfilmentState();
                            if (this.props.user.auth.isStaff) {
                                //this.props.history.push('/menu/'+this.props.cart.menu.menu_name);
                                this.props.updateStateWithGuest({});
                                this.props.updateStateWithCheckout({});
                            } else {
                                this.props.updateStateWithCustomer(response.data.Response.customer, this.props, '/account/orders/' + orderId);
                            }
                        } catch (e) {
                            mpoSentry.addBreadcrumb('Checkout','SubmitOrderErrorAfterSuccess',Sentry.Severity.Info);
                            mpoSentry.captureException(e);
                            this.props.history.push('/account/orders/' + orderId);
                        }
                    }
                } else {
                    this.setState({
                        submittingOrder: false
                    });
                    let errorMsg = response.data.Response[0];
                    if (this.props.cart.order.payment_method_id === 'APPLE') {
                        mpoApplePay.failedPayment(errorMsg);
                    }
                    //logger(errorMsg);
                    if (errorMsg === 'No order present') {
                        mobiscroll.toast({message: 'No order present (session timed out?)', color: 'danger'});
                        this.props.updateStateWithCart({
                            order: {
                                items: []
                            }
                        });
                    } else if (errorMsg.includes('your full name') || errorMsg.includes('your name') || errorMsg.includes('your email') || errorMsg.includes('email address') || errorMsg.includes('your mobile') || errorMsg.includes('name is required') || errorMsg.includes('customer email') || errorMsg.includes('customer mobile')) {
                        mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
                        this.scrollToTopOfCheckout();
                    } else if (errorMsg.includes('time has past') || errorMsg.includes('Minimum notice') || errorMsg.includes('available between') || errorMsg.includes('is available from') || errorMsg.includes('is available until') || errorMsg.includes('closed between') || errorMsg.includes('after last orders')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change Time',
                                icon: 'clock',
                                action: (p) => {
                                    this.showFulfilmentPopup();
                                }
                            }
                        });
                    } else if (errorMsg.includes('Last orders for the selected date') || errorMsg.includes('not available on the selected day') || errorMsg.includes('menu is not available') || errorMsg.includes('earliest date')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change Date',
                                icon: 'empty icon fas fa-calendar-alt',
                                action: (p) => {
                                    this.showFulfilmentPopup();
                                }
                            }
                        });
                    } else if (errorMsg.includes('location is not available')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change',
                                icon: 'location',
                                action: (p) => {
                                    this.showFulfilmentPopup();
                                }
                            }
                        });
                    } else if (errorMsg.includes('delivery slot')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change Slot',
                                icon: 'empty icon fas fa-calendar-alt',
                                action: (p) => {
                                    this.setShowCheckout(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
                                }
                            }
                        });
                    } else if (errorMsg.includes('delivery stop') || errorMsg.includes('delivery location')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change',
                                icon: 'empty icon fas fa-calendar-alt',
                                action: (p) => {
                                    this.setShowCheckout(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
                                }
                            }
                        });
                    } else if (errorMsg.includes('table number') || errorMsg.includes('booking') || errorMsg.includes('umber of people')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Change',
                                icon: 'empty icon fas fa-utensils',
                                action: (p) => {
                                    this.showFulfilmentPopup();
                                }
                            }
                        });
                    } else if (errorMsg.includes('Product unavailable') || errorMsg.includes('minimum order') || errorMsg.includes('not available for')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Back to Cart',
                                icon: 'cart',
                                action: (p) => {
                                    this.props.history.push('/cart');
                                }
                            }
                        });
                    } else if (errorMsg.includes('Account required')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Sign in / Sign up',
                                icon: 'empty icon fas fa-address-card',
                                action: (p) => {
                                    this.props.history.push('/checkout/login');
                                }
                            }
                        });
                    } else if (errorMsg.includes('active customer') || errorMsg.includes('already registered')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Sign in / Reset password',
                                icon: 'empty icon fas fa-address-card',
                                action: (p) => {
                                    this.props.history.push('/checkout/login');
                                }
                            }
                        });
                    } else if (errorMsg.toLowerCase().includes('active facebook customer')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Reset password',
                                icon: 'empty icon fas fa-address-card',
                                action: (p) => {
                                    this.props.history.push('/checkout/login');
                                }
                            }
                        });
                    } else if (errorMsg.toLowerCase().includes('active apple customer')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Sign in with Apple',
                                icon: 'empty icon fas fa-apple',
                                action: (p) => {
                                    this.props.history.push('/checkout/login');
                                }
                            }
                        });
                    } else if (errorMsg.toLowerCase().includes('active google customer')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Sign in with Google',
                                icon: 'empty icon fas fa-google',
                                action: (p) => {
                                    this.props.history.push('/checkout/login');
                                }
                            }
                        });
                    } else if (errorMsg.includes('fulfilment type') || errorMsg.includes('delivery only') || errorMsg.includes('pickup only') || errorMsg.includes('takeaway only')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Choose',
                                icon: '',
                                action: (p) => {
                                    this.showFulfilmentPopup();
                                }
                            }
                        });
                    } else if (errorMsg.includes('an instruction') || errorMsg.includes('instructions') || errorMsg.includes('car reg')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Add Instruction',
                                icon: '',
                                action: (p) => {
                                    this.refs.popupOrderNote.instance.show();
                                }
                            }
                        });
                    } else if (errorMsg.includes('promo') || errorMsg.includes('coupon')) {
                        mobiscroll.snackbar({
                            message: errorMsg,
                            color: 'danger',
                            duration: false,
                            button: {
                                text: 'Edit Voucher/Promo Code',
                                icon: '',
                                action: (p) => {
                                    this.refs.popupPromoCode.instance.show();
                                }
                            }
                        });
                    } else {
                        mobiscroll.toast({message: errorMsg, duration: 6000, color: 'danger'});
                    }
                    //mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                }
            })
            .catch(error => {
                mobiscroll.notification.dismiss();
                logger(error);
                if (this.props.cart.order.payment_method_id === 'APPLE') {
                    mpoApplePay.failedPayment('Unknown error');
                }
                this.setState({
                    submittingOrder: false
                });
                mobiscroll.toast({message: 'Unexpected error: Check your order has not already gone through and try again', color: 'danger'});
                mpoSentry.captureException(error);
            });

        }

    }

    scrollToTopOfCheckout = () => {
        if (!isSafari && !isMobileSafari && !isLegacyEdge && !isIE) {
            //console.log(typeof this.topOfCheckout.current.scrollIntoView === 'function');
            if (this.topOfCheckout && this.topOfCheckout.current && typeof this.topOfCheckout.current.scrollIntoView === 'function')
                this.topOfCheckout.current.scrollIntoView({behavior: 'smooth'});
        }
    }

    _in_app_browser_exit = (event) => {
        logger('Checkout._in_app_browser_exit() called');
        logger(event);
        mpoPayPal._inapp_browser.removeEventListener('loadstop', mpoPayPal._in_app_browser_load_stop);
        mpoPayPal._inapp_browser.removeEventListener('loaderror', mpoPayPal._in_app_browser_load_error);
        mpoPayPal._inapp_browser.removeEventListener('exit', this._in_app_browser_exit);
        mpoPayPal._inapp_browser = null;

        logger('mpoPayPal._inapp_browser_result = ' + mpoPayPal._inapp_browser_result);
        switch (mpoPayPal._inapp_browser_result) {
            case 'pp_success':
                //all good, show success
                //mobiscroll.toast({message: 'Thank you for your payment', color: 'success'});
                this.SubmitOrder();
                break;
            case 'pp_cancel':
                //nothing to do, user knows they cancelled
                mpoSentry.captureMessage('Action cancelled', Sentry.Severity.Warning);
                mobiscroll.toast({message: 'Action cancelled', color: 'info'});
                break;
            case 'pp_error':
                //fetch and display error to user
                var errorMsg = "An error occurred while attempting to authenticate with PayPal";
                mpoSentry.captureMessage(errorMsg, Sentry.Severity.Warning);
                mobiscroll.toast({message: errorMsg, color: 'error'});
                break;
            default:
                // user cancelled
                // var defaultMsg = "An unknown result was specified";
                // mpoSentry.captureMessage(defaultMsg, Sentry.Severity.Warning);
                // mobiscroll.toast({message: defaultMsg, color: 'error'});
        }
        mpoPayPal._inapp_browser_result = null;
        if (mpoPayPal._inapp_browser_result !== 'pp_success') {
            this.setState({
                submittingOrder: false
            });
        }
    }

    showConfirmRemove= (data) => {
        mobiscroll.confirm({
            title: 'Remove product?',
            message: 'Are you sure you wish to remove this product from your cart?',
            okText: 'Yes',
            cancelText: 'No',
            callback: (res) => {
                if (res) {
                    this.updateQuantityPostDataHandler(data);
                } else {
                    // reset to 1 or reload cart
                    this.loadCartPostDataHandler();
                }
            }
        });
    }
    
    onQuantityChangeHandler = (e) => {
        //console.log('Cart onQuantityChangeHandler');
        //console.log(e);
        //console.log(e.target.valueAsNumber);
        const quantity = e.target.valueAsNumber;
        const cartItemSlotId = e.target.getAttribute("data-mpo-cart-item-slot-id");
        //console.log('slotId',cartItemSlotId);

        let cartItem = {
            quantity: quantity,
            cart_item_slot_id: cartItemSlotId
        }

        const data = {
            RequestAction: 'ManageCart',
            sub_action: 'update_quantity',
            store_id: this.props.merchant.id,
            menu_id: this.props.merchant.menu_id,
            menu_item_data: cartItem,
            component: 'Checkout'
        };

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        //console.log(data);

        if (quantity === 0) {
            // confirm delete
            this.showConfirmRemove(data);
        } else {
            this.updateQuantityPostDataHandler(data);
        }
    }

    updateQuantityPostDataHandler = (data) => {
        //logger('Cart updateQuantityPostDataHandler');
        mpoSentry.addBreadcrumb('cartUpdateQty','updateQuantityPostDataHandler',Sentry.Severity.Info);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    // reload cart
                    this.loadCartPostDataHandler();
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }
            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error CH3, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });
    }

    onPromoCodeChange = (e) => {
        const updatedState = updateObject(this.state, {
            coupon_code: e.target.value.toUpperCase()
        });
        this.setState(updatedState);

    }

    updatePromoCode = (event, inst) => {
        if (event.button === 'set') {
            this.updateCheckout({
                coupon_code: this.state.coupon_code,
            })
        }
    }

    onOrderNoteChange = (e) => {
        const updatedState = updateObject(this.state, {
            note: e.target.value
        });
        this.setState(updatedState);
    }

    updateOrderNote = (event, inst) => {
        if (event.button === 'set') {
            this.updateCheckout({
                order_note: this.state.note,
            })
        }
    }

    updateDeliveryAddressManual = (val) => {
        const updatedState = updateObject(this.state, {
            delivery_address_manual: val
        });
        this.setState(updatedState);
    }

    updateBillingAddressManual = (val) => {
        const updatedState = updateObject(this.state, {
            billing_address_manual: val
        });
        this.setState(updatedState);
    }

    resetFulfilmentState = () => {
        this.props.updateStateWithFulfilmentType({});
        this.props.updateStateWithFulfilmentOptions({});
    }

    setShowCheckout = (showCheckout, checkoutExtra) => {
        const updatedState = updateObject(this.state, {
            showCheckout: showCheckout,
            checkoutExtra: checkoutExtra
        });
        this.setState(updatedState);
    }

    isValidDeliveryAddress = () => {
        return (this.props.user.checkout.delivery_address_id > 0 || this.props.user.checkout.delivery_postcode !== "") && this.props.user.checkout.delivery_validated && this.props.user.checkout.delivery_postcode_id > 0;
    }

    onFulfilmentPopupClose = () => {
        //logger('onFulfilmentPopupClose');
        //logger(this.state);
        if (!mpoFulfilment.validFulfilment(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            //logger('show again');
            mobiscroll.toast({message: "Fulfilment type is required", duration: 1500, color: 'danger'});
            setTimeout(() => {
                this.showFulfilmentPopup();
            }, 500);
        // no need to force delivery address component at checkout
        // } else if (mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
        //     this.setShowCheckout(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) || mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowCheckout(false, mpoFulfilment.fulfilmentTypeCodeDelivery);
        } else if (mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {
            this.setShowCheckout(false, mpoFulfilment.fulfilmentTypeCodePickup);
        } else {
            // ensure checkout is displayed, so screen is not left empty
            this.setShowCheckout(true, '');
        }
    }

    scanPromoCodeSuccess = (result) => {
        if (!result.cancelled && result.format === 'QR_CODE') {
            const query = result.text.substring(result.text.lastIndexOf("?"));
            const params = queryString.parse(query);
            const promoCode = params.promo ? params.promo : null;

            if (promoCode) {
                const updatedState = updateObject(this.state, {
                    coupon_code: promoCode
                });
                this.setState(updatedState);
            }
        }
    }

    render = () => {
        //console.log('Checkout render');

        const defaultLogoSquare = process.env.REACT_APP_DEFAULT_LOGO_SQUARE;
        const displayLogoSquare = process.env.REACT_APP_DISPLAY_LOGO_SQUARE === 'true';

        const isOrderPresent = this.props.cart !== undefined && this.props.cart.order !== undefined && this.props.cart.order.num_items && this.props.cart.order.num_items > 0 &&
                                this.props.fulfilmentType !== undefined && Object.keys(this.props.fulfilmentType).length > 0 && this.props.fulfilmentOptions !== undefined && Object.keys(this.props.fulfilmentOptions).length > 0;

        const isDelivery = isOrderPresent && this.props.cart.order.fulfilment_type === mpoFulfilment.fulfilmentTypeDelivery;
        const currencySign = isOrderPresent && this.props.merchant.hasOwnProperty('locale') && this.props.merchant.locale &&
                                this.props.merchant.locale.hasOwnProperty('currency_sign') && this.props.merchant.locale.currency_sign ?
                                this.props.merchant.locale.currency_sign : '';

        const isDeliveryAddressRequired = mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliverySlots = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions); //&& this.isValidDeliveryAddress();
        const showDeliveryStops = mpoFulfilment.isDeliveryStopRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showPickupLocations = mpoFulfilment.isPickupLocationRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
        const showDeliveryOptions = isDeliveryAddressRequired || showDeliverySlots || showDeliveryStops || showPickupLocations;

        const showStudentOptions = isOrderPresent && this.props.cart.menu.hasOwnProperty('is_students_enabled') && parseInt(this.props.cart.menu.is_students_enabled, 10) === 1;
        const customStudentLabel = this.props.cart.menu.hasOwnProperty('student_name_label') && this.props.cart.menu.student_name_label !== null && this.props.cart.menu.student_name_label !== "" ? this.props.cart.menu.student_name_label : "Student";

        let showDeliveryAddress = false;
        let showDeliveryAddressReadOnly = false;
        let deliveryAddressDropdown = null;
        let showDeliveryAddressDropdown = false;
        let showDeliveryAddressLookup = false;
        let showDeliveryAddressManualEntry = false;
        let showCombinedCartCheckout = false;
        let deliveryAddressStr = "";

        let infoText = "";
        let fulfilmentDesc = "";
        let changeFulfilmentOptionDesc = "";
        let cartItems = null;
        let paymentNavItems = null;
        let paymentTabContent = null;
        let paymentSaveCardContent = null;
        let contactlessContent = null;
        let customFieldsContent = null;
        let showOrderNoteButton = false;

        let checkingOutAsText = (!this.props.user.auth.isLoggedIn || this.props.user.auth.isGuest) ? "Guest" : ( this.props.user.auth.isStaff ? "Staff" : "Member");

        if (isOrderPresent) {

            if (mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions)) {

                showDeliveryAddressReadOnly = mpoFulfilment.isDeliverySlotRequired(this.props.fulfilmentType, this.props.fulfilmentOptions);
                showDeliveryAddress = !showDeliveryAddressReadOnly;
                showDeliveryAddressDropdown = this.props.user.auth.isLoggedIn && this.props.user.customer.delivery_addresses.length > 0;
                showDeliveryAddressLookup = !showDeliveryAddressDropdown || this.props.user.checkout.delivery_address_id === 0;
                showDeliveryAddressManualEntry = showDeliveryAddressLookup && (this.state.delivery_address_manual || (this.props.user.checkout.delivery_address === "" && this.props.user.checkout.delivery_postcode !== ""));

                if (showDeliveryAddress) {
                    if (showDeliveryAddressDropdown) {
                        deliveryAddressDropdown = <label>
                            Delivery address
                            <mobiscroll.Select
                                select="single"
                                value={this.props.user.checkout.delivery_address_id}
                                placeholder="Choose address or enter new one"
                                onSet={this.setDeliveryAddressId}
                            >
                                <option key={0} value={0}>New delivery address</option>
                                {this.props.user.customer.delivery_addresses.map((addr, idx) => <option key={addr.id} value={addr.id}>{addr.address}</option>)}
                            </mobiscroll.Select>
                        </label>
                    }
                } else if (this.props.user.checkout.delivery_address_id > 0) {
                    for (var j = 0; j < this.props.user.customer.delivery_addresses.length; j++) {
                        logger(this.props.user.customer.delivery_addresses[j]);
                        if (this.props.user.customer.delivery_addresses[j].hasOwnProperty('id') && parseInt(this.props.user.customer.delivery_addresses[j].id,10) === parseInt(this.props.user.checkout.delivery_address_id,10)) {
                            deliveryAddressStr = this.props.user.customer.delivery_addresses[j].address;
                            break;
                        }
                    }
                } else if (showDeliveryAddressManualEntry) {
                    deliveryAddressStr = this.props.user.checkout.delivery_address1+","+this.props.user.checkout.delivery_city+" "+this.props.user.checkout.delivery_state+" "+this.props.user.checkout.delivery_postcode;
                } else {
                    deliveryAddressStr = this.props.user.checkout.delivery_address;
                }
            }

            fulfilmentDesc = mpoFulfilment.getFulfilmentDesc(this.props.fulfilmentType, this.props.fulfilmentOptions);
            changeFulfilmentOptionDesc = "Change " + mpoFulfilment.getFulfilmentOptionDesc(this.props.fulfilmentType, this.props.fulfilmentOptions);

            infoText = this.state.info ? <div className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(this.state.info)}}></div> : null;

            if (this.props.location.state !== undefined && this.props.location.state.hasOwnProperty('fromRepeatOrder') && this.props.location.state.fromRepeatOrder === true) {
    
                showCombinedCartCheckout = true;

                cartItems = this.props.cart.order.items.map( item => (
                    <CartProductItem
                        key={item.cart_item_slot_id}
                        item={item}
                        onQuantityChangeHandler={this.onQuantityChangeHandler}
                    />
                ) );

            }

            paymentNavItems = this.getPaymentNavItemsJsx();
            paymentTabContent = this.getPaymentTabContentJsx(this.props.cart.order.payment_method_id);
            paymentSaveCardContent = this.getPaymentSaveCardContentJsx(this.props.cart.order.payment_method_id);

            if (this.props.cart.menu.hasOwnProperty('contactless') && this.props.cart.menu.contactless.hasOwnProperty('enabled') && parseInt(this.props.cart.menu.contactless.enabled,10) === 1) {
                if (this.props.cart.menu.contactless.hasOwnProperty(this.props.fulfilmentType.code) && parseInt(this.props.cart.menu.contactless[this.props.fulfilmentType.code],10) === 1) {
                    if (this.props.cart.menu.contactless.hasOwnProperty(this.props.fulfilmentType.code+'_label') && this.props.cart.menu.contactless.hasOwnProperty(this.props.fulfilmentType.code+'_desc')) {
                        contactlessContent = this.getContactlessContentJsx(
                            this.props.cart.menu.contactless[this.props.fulfilmentType.code+'_label'], 
                            this.props.cart.menu.contactless[this.props.fulfilmentType.code+'_desc'])
                        if (this.props.cart.menu.contactless.hasOwnProperty(this.props.fulfilmentType.code+'_notes_link') && parseInt(this.props.cart.menu.contactless[this.props.fulfilmentType.code+'_notes_link'],10) === 1) {
                            //showOrderNoteButton = true;
                        }
                    }
                }
            }

            if (this.props.cart.menu.hasOwnProperty('custom_fields') && Object.keys(this.props.cart.menu.custom_fields).length > 0) {
                customFieldsContent = this.getCustomFieldsContentJsx(this.props.cart.menu.custom_fields);
            }

        }

        return (
            <div className="app-tab">
                <Helmet>
                    <title>{this.props.merchant.name} {this.props.merchant.suburb} Checkout</title>
                </Helmet>
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    {this.props.merchant.id ? 
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            {displayLogoSquare ? <img className="mbsc-pull-left" style={{maxWidth: "80px", paddingRight: "1em"}} src={this.props.merchant.logo_square_url} alt="Logo" onError={(e)=>{e.target.onerror = null; e.target.src=defaultLogoSquare}} /> : null }
                            <mobiscroll.CardTitle>{this.props.merchant.name} {isCustomApp && !isSingleStore && displaySuburbName ? this.props.merchant.suburb : null}</mobiscroll.CardTitle>
                            <mobiscroll.CardSubtitle>
                                <span dangerouslySetInnerHTML={{__html: nl2br(this.props.merchant.address1)}}></span> {!isCustomApp || isSingleStore || !displaySuburbName ? this.props.merchant.suburb : null}
                                {this.props.merchant.phone ? <span style={{fontSize: "0.86em"}}><br/>Ph: {this.props.merchant.phone}</span> : null}
                            </mobiscroll.CardSubtitle>
                        </mobiscroll.CardContent>
                        {isOrderPresent && fulfilmentDesc !== "" ?
                        <mobiscroll.CardFooter>
                            <span className="mbsc-txt-s mbsc-pull-right"><mobiscroll.Button flat={true} onClick={() => {this.showFulfilmentPopup();}} disabled={this.state.submittingOrder}>Change</mobiscroll.Button></span>
                            <span className="mbsc-txt-s">
                                <span className="empty icon fas fa-clock"></span> {!mpoFulfilment.isValidDateTime(this.props.fulfilmentType, this.props.fulfilmentOptions) ? <span style={{color:"red"}}>{fulfilmentDesc}</span> : <span>{fulfilmentDesc}</span> }
                            </span>
                        </mobiscroll.CardFooter>
                        : null }
                        {isOrderPresent && infoText ?
                        <mobiscroll.CardFooter>
                            {infoText}
                        </mobiscroll.CardFooter>
                        : null }
                    </mobiscroll.Card>
                    : null }

                    <div style={{scrollMarginTop: scrollMarginTop}} ref={this.topOfCheckout}></div>

                    {isOrderPresent && this.state.showCheckout ?
                    <React.Fragment>
                    {!this.props.user.auth.isLoggedIn || this.props.user.auth.isGuest || this.props.user.auth.isStaff ?
                    <mobiscroll.FormGroup inset className="mpo-checkout-form">
                        {this.props.user.auth.isStaff ? 
                        <div className="mbsc-form-group-title">Staff Order for Customer</div>
                        :
                        <div className="mbsc-form-group-title" style={{padding: ".5em 1.3em"}}>Guest Checkout<span className="mbsc-pull-right" style={{textTransform: "none"}}><a href="#" onClick={(e) => { e.preventDefault(); this.props.history.push('/checkout/login') }}>Sign in or Create account</a></span></div>
                        }
                        <mobiscroll.Input labelStyle="stacked" value={this.props.user.guest.name} onChange={this.onGuestChange} name="guestName" data-fieldname="name" autoComplete={this.props.user.auth.isStaff ? "off" : "name"}>Name</mobiscroll.Input>

                        <mobiscroll.Input labelStyle="stacked" type="email" value={this.props.user.guest.email} onChange={this.onGuestChange} name="guestEmail" data-fieldname="email" autoComplete={this.props.user.auth.isStaff ? "off" : "email"}>Email</mobiscroll.Input>

                        <mobiscroll.Numpad
                            ref="guestMobile"
                            fill="ltr"
                            template="dddd ddd ddd"
                            allowLeadingZero={true}
                            placeholder=""
                            showOnFocus={true}
                            validate={validateMobile}
                            onSet={this.onGuestMobileChange}>
                            <mobiscroll.Input labelStyle="stacked" value={this.props.user.guest.mobile} name="guestMobile" data-fieldname="mobile">Mobile</mobiscroll.Input>
                        </mobiscroll.Numpad>
                    </mobiscroll.FormGroup>
                    :
                    <React.Fragment>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            <div className="mbsc-form-group-title">Welcome back</div>
                            <span className="mbsc-txt-s" style={{padding: ".5em 1.3em"}}>Logged-in as {this.props.user.customer.name}</span>
                        </mobiscroll.FormGroup>
                        <mobiscroll.FormGroup inset className="mpo-checkout-form">
                            <mobiscroll.Numpad
                                ref="checkoutMobileNew"
                                fill="ltr"
                                template="dddd ddd ddd"
                                allowLeadingZero={true}
                                placeholder=""
                                showOnFocus={true}
                                validate={validateMobile}
                                onSet={this.onCheckoutMobileChange}>
                                <mobiscroll.Input labelStyle="stacked" value={this.props.user.checkout.mobile_new} name="checkoutMobileNew" data-fieldname="mobile_new">Mobile number</mobiscroll.Input>
                            </mobiscroll.Numpad>
                        </mobiscroll.FormGroup>
                    </React.Fragment>
                    }
                    </React.Fragment>
                    : null }

                    {isOrderPresent && showStudentOptions && this.state.showCheckout ?
                        <React.Fragment>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                <div className="mbsc-form-group-title">{customStudentLabel} Details</div>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                <mobiscroll.Input labelStyle="stacked" value={this.props.user.checkout.student_name} onChange={this.onCheckoutChange} name="studentName" data-fieldname="student_name">{customStudentLabel+" Name"}</mobiscroll.Input>

                                <label>
                                    {customStudentLabel+" Class"}
                                    <mobiscroll.Select
                                        select="single"
                                        value={this.props.user.checkout.store_class_id}
                                        placeholder={"Choose"+customStudentLabel+" Class"}
                                        onSet={this.setStudentClass}
                                    >
                                        <option key={0} value={0}>{"Choose "+customStudentLabel+" Class"}</option>
                                        {this.props.cart.menu.availability.student_classes.map((cls, idx) => <option key={cls.id} value={cls.id}>{cls.name}</option>)}
                                    </mobiscroll.Select>
                                </label>
                            </mobiscroll.FormGroup>
                        </React.Fragment>
                        : null }

                    {isOrderPresent && customFieldsContent && this.state.showCheckout ?
                        <React.Fragment>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                <div className="mbsc-form-group-title">{this.props.cart.menu.hasOwnProperty('custom_fields_label') && this.props.cart.menu.custom_fields_label !== null && this.props.cart.menu.custom_fields_label !== "" ? this.props.cart.menu.custom_fields_label : "Order Details"}</div>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                {customFieldsContent}
                            </mobiscroll.FormGroup>
                        </React.Fragment>
                    : null }

                    {showDeliveryAddressReadOnly && this.state.showCheckout ? 
                    <mobiscroll.FormGroup inset className="mpo-checkout-form">
                        <div className="mbsc-form-group-title">Delivery</div>
                        <div className="mbsc-txt-s" style={{padding: ".5em 1.3em"}}>To: {deliveryAddressStr}</div>
                        <div className="mbsc-txt-s" style={{padding: ".5em 1.3em"}}>Slot: {this.props.fulfilmentOptions.datetimeVal} {mpoFulfilment.getSelectedDeliverySlotDesc(this.props.fulfilmentType, this.props.fulfilmentOptions)}</div>
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="empty icon fas fa-map-marked-alt" style={{textTransform: "capitalize"}} onClick={() => { this.showFulfilmentPopup(); }} disabled={this.state.submittingOrder}>Change Delivery Address or Slot</mobiscroll.Button>
                        </div>
                    </mobiscroll.FormGroup> 
                    : null }

                    {showDeliveryAddress && this.state.showCheckout ? 
                    <mobiscroll.FormGroup inset className="mpo-checkout-form">
                        <div className="mbsc-form-group-title">Delivery</div>

                        {deliveryAddressDropdown}

                        {showDeliveryAddressLookup ? 
                        <React.Fragment>
                            <PlacesAutocomplete
                                value={this.props.user.checkout.delivery_address}
                                onChange={this.onDeliveryAddressChange}
                                onSelect={this.onDeliveryAddressSelect}
                                onError={this.onDeliveryAddressError}
                                searchOptions={this.state.placesSearchOptions}
                                shouldFetchSuggestions={this.props.user.checkout.delivery_address.length > 3}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <React.Fragment>
                                    <mobiscroll.Input 
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                        labelStyle="stacked" 
                                        placeholder="Start typing your address here" 
                                        type="text" 
                                        name="searchDeliveryAddress">{showDeliveryAddressDropdown ? "New delivery address" : "Delivery address"}</mobiscroll.Input>
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div className="suggestion-item">Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active ? 'suggestion-item suggestion-item-active': 'suggestion-item';
                                        //console.log(suggestion);
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </React.Fragment>
                                )}
                            </PlacesAutocomplete>
                            {showDeliveryAddressManualEntry ?
                            <React.Fragment>
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_address1} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress1" data-fieldname="delivery_address1" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line1"}>{this.props.merchant.locale.labels.address1}</mobiscroll.Input>
                                {(this.props.merchant.locale.labels.hasOwnProperty('address2') && this.props.merchant.locale.labels.address2 !== '') ?
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_address2} onChange={this.onDeliveryAddressManualChange} name="deliveryAddress2" data-fieldname="delivery_address2" autoComplete={this.props.user.auth.isStaff ? "off" : "address-line2"}>{this.props.merchant.locale.labels.address2}</mobiscroll.Input>
                                : null}
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "capitalize"}} value={this.props.user.checkout.delivery_city} onChange={this.onDeliveryAddressManualChange} name="deliveryCity" data-fieldname="delivery_city" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level2"}>{this.props.merchant.locale.labels.city_suburb}</mobiscroll.Input>
                                {(this.props.merchant.locale.labels.hasOwnProperty('state') && this.props.merchant.locale.labels.state !== '') ?
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.delivery_state} onChange={this.onDeliveryAddressManualChange} name="deliveryState" data-fieldname="delivery_state" autoComplete={this.props.user.auth.isStaff ? "off" : "address-level1"}>{this.props.merchant.locale.labels.state}</mobiscroll.Input>
                                : null}
                                <mobiscroll.Input labelStyle="stacked" style={{textTransform: "upperCase"}} value={this.props.user.checkout.delivery_postcode} onChange={this.onDeliveryAddressManualChange} name="deliveryPostcode" data-fieldname="delivery_postcode" autoComplete={this.props.user.auth.isStaff ? "off" : "postal-code"}>{this.props.merchant.locale.labels.postcode}</mobiscroll.Input>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className="mbsc-padding" style={{paddingTop: "0.5em", paddingBottom: "0"}}><span className="mbsc-txt-s" style={{textTransform: "none"}}><a href="#" onClick={(e) => { e.preventDefault(); this.updateDeliveryAddressManual(true); }}>Enter address manually instead</a></span></div>
                            </React.Fragment>
                            }
                        </React.Fragment>
                        : null }
                    </mobiscroll.FormGroup> 
                    : null }

                    {isOrderPresent ?
                    <React.Fragment>
                        {this.state.showCheckout ?
                        <React.Fragment>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                <div className="mbsc-form-group-title">Payment</div>
                                <mobiscroll.TabNav display="inline">
                                    {paymentNavItems}
                                </mobiscroll.TabNav>
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                {paymentTabContent}
                            </mobiscroll.FormGroup>

                            {paymentSaveCardContent}

                            {cartItems}

                            <div className="mbsc-padding" style={{paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Products total</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.total).toFixed(2)}</span>
                            </div>
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Rewards discount</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.sub_total - this.props.cart.order.total_after_rewards).toFixed(2)}</span>
                            </div>
                            {this.props.cart.order.total_after_discount < this.props.cart.order.total_after_rewards ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Other discount</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.total_after_rewards - this.props.cart.order.total_after_discount).toFixed(2)}</span>
                            </div>
                            : null }
                            {this.props.cart.order.surcharge_amount > 0 ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Surcharge</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.surcharge_amount).toFixed(2)}</span>
                            </div>
                            : null }
                            {isDelivery ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Delivery fee{mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !this.isValidDeliveryAddress() ? " (estimate*)" : null}</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.delivery_fee).toFixed(2)}</span>
                            </div>
                            : null}
                            {this.props.cart.order.convenience_fee > 0 ?
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Preorder fee</span>
                                <span className="mbsc-txt-s mbsc-pull-right">-{currencySign}{parseFloat(this.props.cart.order.convenience_fee).toFixed(2)}</span>
                            </div>
                            : null }
                            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                                <span className="mbsc-txt-s">Order total</span>
                                <span className="mbsc-txt-s mbsc-pull-right">{currencySign}{parseFloat(this.props.cart.order.grand_total).toFixed(2)}</span>
                            </div>
                            {isDelivery && mpoFulfilment.isDeliveryAddressRequired(this.props.fulfilmentType, this.props.fulfilmentOptions) && !this.isValidDeliveryAddress() ?
                            <div className="mbsc-align-center mbsc-padding" style={{paddingTop: "0.2em", paddingBottom: 0}}>
                                <span className="mbsc-txt-s">*Enter your delivery address to confirm delivery fee</span>
                            </div>
                            : null }

                            {contactlessContent !== null ?
                            <React.Fragment>
                                <mobiscroll.FormGroup inset className="mpo-checkout-form">
                                    {contactlessContent}
                                </mobiscroll.FormGroup>
                                {showOrderNoteButton ?
                                <div className="mbsc-btn-group-block" style={{marginTop: 0}}>
                                    <mobiscroll.Button icon="pencil" style={{marginTop: 0}} onClick={() => {this.refs.popupOrderNote.instance.show()}} disabled={this.state.submittingOrder}>{this.state.note !== "" ? "Edit" : "Add"} instructions</mobiscroll.Button>
                                </div>
                                : null}
                            </React.Fragment>
                            : null }

                            <div className="mbsc-btn-group" style={{textAlign: "center", margin: 0}}>
                                <span className="mbsc-txt-s">
                                    <mobiscroll.Button icon="bullhorn" flat={true} onClick={() => {this.refs.popupPromoCode.instance.show()}} disabled={this.state.submittingOrder}>{this.state.coupon_code !== undefined && this.state.coupon_code !== null && this.state.coupon_code !== "" ? this.state.coupon_code : "Enter voucher or promo code"} </mobiscroll.Button>
                                </span>
                                {parseInt(this.props.cart.menu.allow_order_notes,10) === 1 ?
                                <span className="mbsc-txt-s">
                                    <mobiscroll.Button icon="pencil" flat={true} onClick={() => {this.refs.popupOrderNote.instance.show()}} disabled={this.state.submittingOrder}>{this.state.note !== "" ? "Edit" : "Add"} instructions</mobiscroll.Button>
                                </span>
                                : null }
                            </div>

                            <div className="mbsc-btn-group-block">
                                <div className="mbsc-align-center">
                                    {fulfilmentDesc} {mpoFulfilment.isDineinFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) || mpoFulfilment.isBookingFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) ? "at" : "from"} {this.props.merchant.name} {this.props.merchant.suburb}:
                                </div>
                                {this.props.cart.order.payment_method_id === 'PP' ?
                                <React.Fragment>
                                    {/*
                                    <div style={{textAlign: "center"}}>
                                        <a href="#" onClick={() => { this.SubmitOrder(true); }}><img src="./img/paypal-checkout-logo-large.png" /></a>
                                    </div>
                                    */}
                                    <mobiscroll.Button onClick={() => { this.SubmitOrder(true); }} disabled={this.state.submittingOrder} color="success" style={{color: '#fff',textTransform: "capitalize"}}>Check out with PayPal &amp; Place Order for {this.props.fulfilmentType.verb}</mobiscroll.Button>
                                </React.Fragment>
                                :
                                (this.props.cart.order.payment_method_id === 'APPLE' ?
                                <ApplePayButton className="apple-login-btn" onClick={(e) => { e.preventDefault(); if (!this.state.submittingOrder) { this.SubmitOrder(); } }} theme="dark">
                                    {"Check out with"}
                                </ApplePayButton>
                                :
                                (this.props.cart.order.payment_method_id === 'GOOGLE' ?
                                    (isCordova() ?
                                        <button onClick={(e) => { e.preventDefault(); if (!this.state.submittingOrder) { this.SubmitOrder(); } }} data-enhanced="false" className={"gpay-button black checkout en"} />
                                        :
                                        <GooglePayButton
                                            environment="PRODUCTION"
                                            buttonSizeMode={"fill"}
                                            buttonType={"checkout"}
                                            paymentRequest={{
                                                apiVersion: 2,
                                                apiVersionMinor: 0,
                                                allowedPaymentMethods: [
                                                    {
                                                        type: 'CARD',
                                                        parameters: {
                                                            allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                                            allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                                        },
                                                        tokenizationSpecification: {
                                                            type: 'PAYMENT_GATEWAY',
                                                            parameters: {
                                                                gateway: mpoGooglePay._gateway,
                                                                gatewayMerchantId: mpoGooglePay._gateway_id,
                                                            },
                                                        },
                                                    },
                                                ],
                                                merchantInfo: {
                                                    merchantId: mpoGooglePay._merchant_id,
                                                    merchantName: mpoGooglePay._merchant_name,
                                                },
                                                transactionInfo: {
                                                    totalPriceStatus: 'FINAL',
                                                    totalPriceLabel: 'Total',
                                                    totalPrice: this.props.cart.order.grand_total,
                                                    currencyCode: mpoGooglePay._currency_code,
                                                    countryCode: mpoGooglePay._country_code,
                                                },
                                            }}
                                            onClick={e => {
                                                if (!this.validateCheckoutFormFields()) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            onLoadPaymentData={paymentRequest => {
                                                //console.log('load payment data', paymentRequest);
                                                this._wallet_payment_callback(true, paymentRequest.paymentMethodData.tokenizationData.token, 'Google');
                                            }}
                                        />
                                    )
                                :
                                (this.props.cart.order.payment_method_id === 'CB' ?
                                <mobiscroll.Button onClick={() => { this.SubmitOrder(); }} disabled={this.state.submittingOrder} color="success" style={{color: '#fff',textTransform: "capitalize"}}>Check out with Coinbase &amp; Place Order for {this.props.fulfilmentType.verb}</mobiscroll.Button>
                                :
                                <mobiscroll.Button onClick={() => { this.SubmitOrder(); }} disabled={this.state.submittingOrder} color="success" style={{color: '#fff',textTransform: "capitalize"}}>Place Order for {this.props.fulfilmentType.verb}</mobiscroll.Button>
                                )
                                )
                                )
                                }
                                <div className="mbsc-align-center mbsc-txt-s">{checkingOutAsText} Checkout</div>
                                {!isCustomApp && this.props.cart.order.payment_method_id === 'PP' ?
                                <div className="mbsc-align-center mbsc-txt-s">A small surcharge may apply to payments made through PayPal</div>
                                : null }
                            </div> 

                            {isOrderPresent && fulfilmentDesc !== "" && !mpoFulfilment.isCustomFulfilmentType(this.props.fulfilmentType, this.props.fulfilmentOptions) ?
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="empty icon fas fa-clock" style={{textTransform: "capitalize"}} onClick={() => { this.showFulfilmentPopup(); }} disabled={this.state.submittingOrder}>{changeFulfilmentOptionDesc}</mobiscroll.Button>
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/cart') }} disabled={this.state.submittingOrder}>Back to Cart</mobiscroll.Button>
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }} disabled={this.state.submittingOrder}>Back to Menu</mobiscroll.Button>
                            </div>
                            : 
                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/cart') }} disabled={this.state.submittingOrder}>Back to Cart</mobiscroll.Button>
                                <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }} disabled={this.state.submittingOrder}>Back to Menu</mobiscroll.Button>
                            </div> 
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                        {showDeliveryOptions ?
                        <React.Fragment>
                            {isDeliveryAddressRequired ?
                            <DeliveryAddress headerText="Delivery" showTick={this.isValidDeliveryAddress()} bodyText={this.isValidDeliveryAddress() ? "We deliver to your address. |deliveryEstimate|" : "Enter your delivery address to check we deliver to your area"} showValidateButton={true} />
                            : null }

                            {showDeliverySlots ?
                            <DeliverySlot deliveryPostcodeId={this.props.user.checkout.delivery_postcode_id} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Slot" bodyText="Choose an available delivery date and time slot" />
                            : null }

                            {showDeliveryStops ?
                            <DeliveryStop reloadCart={this.loadCartPostDataHandler} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Delivery Location" bodyText="Choose an available delivery date and location" />
                            : null }

                            {showPickupLocations ?
                            <PickupLocation reloadCart={this.loadCartPostDataHandler} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} headerText="Pickup Location" bodyText="Choose an available date and location" />
                            : null }

                            <div className="mbsc-btn-group-block">
                                <mobiscroll.Button color="success" style={{color: '#fff'}} disabled={(isDeliveryAddressRequired && !this.isValidDeliveryAddress()) || (showDeliverySlots && !mpoFulfilment.isValidDeliverySlot(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showDeliveryStops && !mpoFulfilment.isValidDeliveryStop(this.props.fulfilmentType, this.props.fulfilmentOptions)) || (showPickupLocations && !mpoFulfilment.isValidPickupLocation(this.props.fulfilmentType, this.props.fulfilmentOptions))} onClick={() => { this.setShowCheckout(true, ''); }}>Proceed to Checkout</mobiscroll.Button>
                            </div>
                        </React.Fragment>
                        : null }
                        </React.Fragment>
                        }
                    </React.Fragment> 
                    :
                    <div className="mbsc-empty">
                        {this.state.submittingOrder ? 
                        <h3>Thank you!</h3>
                        :
                        <h3>Your cart is empty</h3>
                        }
                        {(!this.state.submittingOrder && this.props.cart.menu) || this.props.user.auth.isStaff ? 
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="arrow-left5" onClick={() => { this.props.history.push('/menu/'+this.props.cart.menu.menu_name) }}>Back to Menu</mobiscroll.Button>
                        </div> 
                        : null }
                    </div>
                    }
    
                </mobiscroll.Form>

                <mobiscroll.Popup
                    ref="popupPromoCode"
                    headerText={mobiscroll.platform.name !== 'ios' ? "Voucher/Promo Code" : null}
                    responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                    layout="liquid"
                    buttons={[{text: 'Save', handler: 'set'}]}
                    onBeforeClose={this.updatePromoCode}
                >
                    <mobiscroll.Form>
                        <mobiscroll.Input inputStyle="box" labelStyle="stacked" icon="bullhorn" iconAlign="left" placeholder=""
                        type="text" name="promoCode" style={{textTransform: "uppercase"}}
                        value={this.state.coupon_code} onChange={this.onPromoCodeChange}>Enter voucher or promo code</mobiscroll.Input>
                        { isCordova() ?
                        <div className="mbsc-btn-group-block">
                            <mobiscroll.Button icon="empty icon fas fa-qrcode" onClick={() => {mpoScanner.ScanQrCode(this.scanPromoCodeSuccess);}}>Scan QR Code</mobiscroll.Button>
                        </div>
                        : null }
                    </mobiscroll.Form>
                </mobiscroll.Popup>

                <mobiscroll.Popup
                    ref="popupOrderNote"
                    headerText={mobiscroll.platform.name !== 'ios' ? "Instructions" : null}
                    responsive={getPopupResponsiveSettings(mobiscroll.platform.name === 'ios')}
                    layout="liquid"
                    buttons={[{text: 'Save', handler: 'set'}]}
                    onBeforeClose={this.updateOrderNote}
                >
                    <mobiscroll.Form>
                        <mobiscroll.Textarea inputStyle="box" labelStyle="stacked" icon="pencil" iconAlign="left" placeholder="Special order instructions" value={this.state.note} onChange={this.onOrderNoteChange}>Instructions</mobiscroll.Textarea>
                    </mobiscroll.Form>
                </mobiscroll.Popup>

                <FulfilmentPopup ref={this.fulfilmentModal} onClose={this.onFulfilmentPopupClose} updateStateWithFulfilmentType={this.props.updateStateWithFulfilmentType} updateStateWithFulfilmentOptions={this.props.updateStateWithFulfilmentOptions} />

            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        merchant: state.menu.merchant,
        fulfilmentType: state.menu.fulfilmentType,
        fulfilmentOptions: state.menu.fulfilmentOptions,
        cart: state.cart,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithMerchant: (merchant) => dispatch(actions.setMerchantAction(merchant)),
        updateStateWithFulfilmentType: (fulfilmentType) => dispatch(actions.setFulfilmentTypeAction(fulfilmentType)),
        updateStateWithFulfilmentOptions: (fulfilmentOptions) => dispatch(actions.setFulfilmentOptionsAction(fulfilmentOptions)),
        updateStateWithCart: (cart) => {
            dispatch(actions.setCartAction(cart));
            dispatch(actions.setOrderAction(cart.order));
        },
        updateStateWithCustomer: (customer, ownProps, redirect) => {
            //console.log(ownProps);
            //console.log('redirect', redirect);
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
        updateStateWithGuest: (guest) => dispatch(actions.setGuestAction(guest)),
        updateStateWithCheckout: (checkout) => dispatch(actions.setCheckoutAction(checkout))
    }
}

//export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler( Checkout, axiosInstance ));
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
