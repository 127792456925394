import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import {logger, nl2br, openWindow, isProduction, isCordova} from '../../shared/utility';
import OrderProductItem from './OrderProductItem';
import { mpoAppStore } from '../../lib/AppStore';
import { mpoOneSignal } from '../../lib/OneSignal';
import { mpoFulfilment } from '../../lib/Fulfilment';

const isCustomApp = !isProduction() || process.env.REACT_APP_CUSTOM_APP === 'true';

class OrderDetailsItem extends Component {

    render = () => {
        logger(this.props);

        const order = this.props.order;
        const isActive = parseInt(order.nstatus,10) < 5;
        const isDelivery = order.fulfilment_type === mpoFulfilment.fulfilmentTypeDelivery;
        //const isDineIn = order.fulfilment_type === mpoFulfilment.fulfilmentTypeDinein;
        //const isBooking = order.fulfilment_type === mpoFulfilment.fulfilmentTypeBooking;
        const discountSaving = parseFloat(order.sub_total + order.delivery_fee + order.convenience_fee + order.store_surcharge - order.store_discount - order.total);
        let requestedFor = order.fulfilment_type+" ";
        // if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('fulfilment_type_label') && order.settings.fulfilment_type_label !== null && order.settings.fulfilment_type_label.trim() !== "") {
        //     requestedFor = order.settings.fulfilment_type_label+" ";
        // }

        switch (order.fulfilment_type) {
            case mpoFulfilment.fulfilmentTypeDinein:
                if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('dinein_verb') && order.settings.dinein_verb !== null && order.settings.dinein_verb.trim() !== "") {
                    requestedFor = order.settings.dinein_verb+" ";
                }
                if (order.table_num !== null && order.table_num !== "") {
                    if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('table_num_label') && order.settings.table_num_label !== null && order.settings.table_num_label.trim() !== "") {
                        requestedFor = order.settings.table_num_label + " " + order.table_num;
                    } else {
                        requestedFor += "Table " + order.table_num;
                    }
                }
                break;
            case mpoFulfilment.fulfilmentTypeBooking:
                if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('booking_verb') && order.settings.booking_verb !== null && order.settings.booking_verb.trim() !== "") {
                    requestedFor = order.settings.booking_verb+" ";
                }
                if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('booking_ref_label') && order.settings.booking_ref_label !== null && order.settings.booking_ref_label.trim() !== "") {
                    requestedFor = order.settings.booking_ref_label + " " + order.booking_ref;
                } else {
                    requestedFor += "Ref " + order.booking_ref;
                }
                if (/*order.booking_time_type === 'user' &&*/ parseInt(order.fulfilment_asap, 10) === 1 && order.requested_for_date === order.created_date) {
                    requestedFor += "\nASAP (approx " + order.requested_for_time + ")";
                }
                else if (order.delivery_time_type === 'user' || order.delivery_time_type === 'stops') {
                    requestedFor += "\n"+order.requested_for_date + " at " + order.requested_for_time;
                }
                if (parseInt(order.booking_pax,10) > 0) {
                    requestedFor += "\n"+order.booking_pax+" People"
                }
                break;
            case mpoFulfilment.fulfilmentTypeDelivery:
                if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('deliver_verb') && order.settings.deliver_verb !== null && order.settings.deliver_verb.trim() !== "") {
                    requestedFor = order.settings.deliver_verb+" ";
                }
                if (order.delivery_time_type === 'user' && parseInt(order.fulfilment_asap, 10) === 1 && order.requested_for_date === order.created_date) {
                    requestedFor += " ASAP (approx " + order.requested_for_time + ")";
                }
                else if (order.delivery_time_type === 'user' || order.delivery_time_type === 'stops') {
                    requestedFor += order.requested_for_date + " at approx " + order.requested_for_time;
                }
                break;
            case mpoFulfilment.fulfilmentTypePickup:
                if (order.hasOwnProperty('settings') && order.settings.hasOwnProperty('pickup_verb') && order.settings.pickup_verb !== null && order.settings.pickup_verb.trim() !== "") {
                    requestedFor = order.settings.pickup_verb+" ";
                }
                if (order.delivery_time_type === 'user' && parseInt(order.fulfilment_asap, 10) === 1 && order.requested_for_date === order.created_date) {
                    requestedFor += " ASAP (approx " + order.requested_for_time + ")";
                }
                else {
                    requestedFor += order.requested_for_date + " at " + order.requested_for_time;
                }
                break;
            default:
                // custom
                requestedFor = null;
        }

        let orderStatus;
        //let orderStatusClass = "info";
        switch (parseInt(order.nstatus)) {
            case 1:
                orderStatus = 'Submitted';
                break;
            case 2:    
                orderStatus = 'Confirmed';
                break;
            case 3:    
                orderStatus = 'In progress';
                break;
            case 4:    
                orderStatus = 'Ready';
                break;
            case 5:    
                orderStatus = 'Complete';
                break;
            case 6:    
                orderStatus = 'Accepted';
                break;
            case 7:
            case 8:    
                orderStatus = 'Complete';
                break;
            case 9:    
                orderStatus = 'Cancelled';
                break;
            case 10:    
                orderStatus = 'Rejected';
                break;
            default:
                orderStatus = '';
        }

        let orderItems = null;
        if (order.items) {
            orderItems = order.items.map( item => (
                <OrderProductItem
                    key={item.id}
                    item={item}
                    currencySign={order.currency_sign}
                />
            ) );
        }

        const appDownloadInfo = mpoAppStore.getAppDownloadInfo();

        return (
            <React.Fragment>
                {isActive && order.order_confirmation !== "" ? 
                    <mobiscroll.Note color="success">
                        <div className="mbsc-align-center" dangerouslySetInnerHTML={{__html: nl2br(order.order_confirmation)}}></div>    
                    </mobiscroll.Note> 
                : null }
                {order.hasOwnProperty('payment_terms') && order.payment_terms !== "" ?
                    <mobiscroll.Note color="primary">
                        <p className="mbsc-align-center" style={{fontWeight: 'bold'}}>Payment Terms</p>
                        <p className="mbsc-align-center" dangerouslySetInnerHTML={{__html: nl2br(order.payment_terms)}}></p>
                    </mobiscroll.Note>
                : null }
                {order.hasOwnProperty('merchant_name') && order.merchant_name !== "" ?
                    <mobiscroll.Note color="primary">
                        <div className="mbsc-align-center">Payment will appear on your statement as <span style={{fontWeight: 'bold'}}>{order.merchant_name}</span></div>
                    </mobiscroll.Note>
                : null }
                {order.standing_order_id !== null && parseInt(order.standing_order_id,10) > 0 ?
                    <mobiscroll.Note color="info">
                        <div className="mbsc-align-center">Order generated by Standing Order.</div>
                    </mobiscroll.Note>
                : null }

                <mobiscroll.Card>
                    <mobiscroll.CardContent>
                        <span className="mbsc-txt-s mbsc-pull-right">{order.submitted}</span>
                        <span className="fas fa-store pull"></span> {order.store_name} 
                        <span className="mbsc-txt-s"><br/>{order.store_address1} {order.store_suburb}</span>
                        {order.store_phone !== "" ? <span className="mbsc-txt-s"><br/>Phone: {order.store_phone}</span> : null }
                        <p className="mbsc-align-center">Order #{order.id} ({orderStatus}){order.pos_order_id !== null && order.pos_order_id !== "" ? <span className="mbsc-txt-s"><br/>POS Order #{order.pos_order_id}</span> : null }<br />for {order.contact_name}</p>
                    </mobiscroll.CardContent>
                    <mobiscroll.CardFooter>
                        {requestedFor ? <p className="mbsc-align-center" dangerouslySetInnerHTML={{__html: nl2br(requestedFor)}}></p> : null }
                        {isDelivery ? <div className="mbsc-txt-s" style={{textAlign: "center"}}>To: {order.delivery_address}<br/></div> : null }
                        {isDelivery && order.delivery_slot ? <div className="mbsc-txt-s" style={{textAlign: "center"}}>Slot: {order.invoice_date} {order.delivery_slot}<br/></div> : null }
                        
                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <span>Your Order</span>
                        </div>
                        {orderItems}

                        {order.note !== null && order.note !== "" ?
                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <span>Special Instructions</span><br/>
                            <span className="mbsc-txt-s" dangerouslySetInnerHTML={{__html: nl2br(order.note)}}></span>
                        </div>
                        : null}

                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <span>Totals</span>
                        </div>
                        {order.sub_total !== order.total ? 
                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Sub-total</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(order.sub_total).toFixed(2)}</span>
                        </div>
                        : null}
                        {order.store_discount > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Discounts</span>
                            <span className="mbsc-txt-s mbsc-pull-right">-{order.currency_sign}{parseFloat(order.store_discount).toFixed(2)}</span>
                        </div>
                        : null}
                        {order.coupon_value > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Discounts ({order.coupon_code})</span>
                            <span className="mbsc-txt-s mbsc-pull-right">-{order.currency_sign}{parseFloat(order.coupon_value).toFixed(2)}</span>
                        </div>
                        : null}
                        {order.store_surcharge > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Surcharge</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(order.store_surcharge).toFixed(2)}</span>
                        </div>
                        : null }
                        {discountSaving > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Discount/Savings</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(discountSaving).toFixed(2)}</span>
                        </div>
                        : null }
                        {isDelivery ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Delivery fee</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(order.delivery_fee).toFixed(2)}</span>
                        </div>
                        : null}
                        {order.convenience_fee > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Preorder fee</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(order.convenience_fee).toFixed(2)}</span>
                        </div>
                        : null }
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Order total</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.currency_sign}{parseFloat(order.total).toFixed(2)}</span>
                        </div>
                        {order.rewards_awarded > 0 ?
                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Rewards collected</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.rewards_awarded}</span>
                        </div>
                        : null }
                        {order.rewards_used > 0 ?
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <span className="mbsc-txt-s">Rewards redeemed</span>
                            <span className="mbsc-txt-s mbsc-pull-right">{order.rewards_used}</span>
                        </div>
                        : null }

                    </mobiscroll.CardFooter>
                </mobiscroll.Card>

                {isActive && isCordova() && !mpoOneSignal.IsRegistered() && mobiscroll.platform.name === 'ios' ?
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>Get Notified</mobiscroll.Button>
                </div>
                : null}

                {isActive && isDelivery && order.delivery_tracking_url !== null && order.delivery_tracking_url !== "" ?
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); openWindow(order.delivery_tracking_url, '_system', 'location=no', isCustomApp); }}>Track Your Driver</mobiscroll.Button>
                </div>
                : null}

                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/feedback') }}>Leave Feedback</mobiscroll.Button>
                </div>

                {order.menu_name !== "" ? 
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/menu/'+order.menu_name) }}>Place another Order</mobiscroll.Button>
                </div>
                : null}

                {isCustomApp && this.props.modules.standing_orders ?
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    {order.standing_order_id !== null && parseInt(order.standing_order_id, 10) > 0 ?
                        <mobiscroll.Button block={true} onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push('/account/standingorder/'+order.standing_order_id)
                        }}>Edit Standing Order</mobiscroll.Button>
                        :
                        <mobiscroll.Button block={true} onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push('/account/standingorder')
                        }}>Create Standing/Recurring Order</mobiscroll.Button>
                    }
                </div>
                : null}

                {/*
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button style={{background: '#ee731a', borderColor: '#ee731a', color: '#fff'}} block={true} onClick={(e) => { e.preventDefault(); }}>Venue Checkout</mobiscroll.Button>
                </div>
                */}

                {appDownloadInfo.downloadAppUrl !== "" ?
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
                </div>
                : null}

            </React.Fragment>
        );
    }

}

export default withRouter(OrderDetailsItem);